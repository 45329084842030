// Font Family
$cerebrisans: 'CerebriSans';


    
// text Colors ---------------
$white: #ffffff;
$black: #333;
$gray: #444;

// Theme Color
$theme-color-blue: #4e97fd;
$theme-color-red: #e4573d;

// Responsive Variables 
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
