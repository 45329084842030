/*-------- 8. Testimonial style ---------*/

.testimonial-area {
    position: relative;
    .pagingInfo {
        position: absolute;
        bottom: 13%;
        left: 10%;
        font-size: 18px;
        font-weight: 700;
        color: #333;
        z-index: 5;
    }
}

.testimonial-active-1 , 
.testimonial-active-3 {
    margin-left: -15px;
    margin-right: -15px;
    .testimonial-plr-1 {
        padding: 0 15px;
    }
}

.single-testimonial {
    position: relative;
    padding: 56px 40px 55px;
    background-image: linear-gradient(228deg,#f6fafe 0%,#edeff6 82%);
    border-radius: 5px;
    @media #{$xx-layout} {
        padding: 56px 32px 55px;
    }
    @media #{$lg-layout} {
        padding: 56px 30px 55px;
    } 
    @media #{$md-layout} {
        padding: 56px 20px 55px;
    } 
    @media #{$xs-layout} {
        padding: 46px 30px 45px;
    } 
    &::before {
        content: '';
        position: absolute;
        top: 44px;
        right: 38px;
        width: 34px;
        height: 28px;
        background: url(../images/icon-img/testimonial-icon.png) no-repeat center;
        background-size: 100% auto;
    }
    h4 {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
    }
    p {
        font-size: 18px;
        line-height: 30px;
        color: $black;
        margin: 22px 0 34px;
        @media #{$lg-layout} {
            margin: 22px 0 23px;
        }
        @media #{$md-layout} {
            margin: 22px 0 23px;
        }
        @media #{$xs-layout} {
            margin: 22px 0 23px;
        }
    }
    .client-info {
        h5 {
            font-size: 20px;
            font-weight: bold;
            margin: 0 0 10px;
        }
        span {
            font-size: 15px;
            color: #444;
        }
    }
}

.single-testimonial-2 {
    position: relative;
    padding: 77px 80px 111px;
    background-image: linear-gradient(235deg,#e6eef5 0%,#edeff6 100%);
    border-radius: 5px;
    @media #{$xl-layout} {
        padding: 66px 65px 111px;
    }
    @media #{$lg-layout} {
        padding: 66px 50px 111px;
    }
    @media #{$md-layout} {
        padding: 66px 50px 111px;
    }
    @media #{$xs-layout} {
        padding: 50px 20px 80px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 58px;
        right: 49px;
        width: 60px;
        height: 48px;
        background: url(../images/icon-img/testimonial-icon.png) no-repeat center;
        background-size: 100% auto;
        @media #{$lg-layout} {
            top: 40px;
        }
        @media #{$md-layout} {
            top: 40px;
        }
        @media #{$xs-layout} {
            top: 20px;
            right: 20px;
            width: 35px;
            height: 35px;
        }
    }
    h4 {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
    }
    p {
        font-size: 24px;
        line-height: 1.5;
        color: $black;
        margin: 15px 0 41px;
        @media #{$lg-layout} {
            font-size: 20px;
            margin: 15px 0 20px;
        }
        @media #{$md-layout} {
            font-size: 20px;
            margin: 15px 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 18px;
            margin: 15px 0 20px;
        }
    }
    .client-info-2 {
        h5 {
            font-size: 20px;
            font-weight: bold;
            margin: 0 0 10px;
        }
        span {
            font-size: 15px;
            color: #444;
        }
    }
}

.nav-style-3 {
    > span {
        position: absolute;
        bottom: 10%;
        right: 8.5%;
        cursor: pointer;
        color: #111;
        width: 52px;
        height: 52px;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        background: rgba(255,255,255,.5);
        z-index: 9;
        font-size: 28px;
        box-shadow: 0 0 5px rgba(0,0,0,.01);
        i {
            line-height: 52px;
            font-weight: 300;
            @media #{$xs-layout} {
                line-height: 35px;
            }
        }
        @media #{$xs-layout} {
            width: 35px;
            height: 35px;
            font-size: 16px;
            bottom: 12%;
        }
        &:hover {
            color: $theme-color-blue;
            background: rgba(255,255,255,1);
        }
        &.testimonial-icon-2-prev {
            margin-right: 62px;
            @media #{$xs-layout} {
                margin-right: 45px;
            }
        }
    }
}



