/*-----------------------------------------------------------------------------------
    
    Template Medizin - Medical Equipment eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Animation CSS
    3. Header style
    4. Slider style
    5. Banner style
    6. Section title style
    7. Brand logo style
    8. Testimonial style
    9. Contact us style
    10. Footer style
    11. Blog style
    12. Others style
    13. Breadcrumb style
    14. Sidebar style
    15. Product details style
    16. Cart style
    17. Checkout style
    18. Wishlist style
    19. About us style
    20. 404 style
    21. My account style
    22. Login register style
    23. Compare style
    24. Blog details style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'animation';
@import 'header';
@import 'slider';
@import 'banner';
@import 'section-title';
@import 'product';
@import 'brand-logo';
@import 'testimonial';
@import 'contact-us'; 
@import 'footer';
@import 'blog';
@import 'others';
@import 'breadcrumb';
@import 'sidebar';
@import 'product-details';
@import 'cart';
@import 'checkout';
@import 'wishlist';
@import 'about-us';
@import '404';
@import 'my-account';
@import 'login-register';
@import 'compare';
@import 'blog-details';






