/*-----------------------------------------------------------------------------------
    
    Template Medizin - Medical Equipment eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Animation CSS
    3. Header style
    4. Slider style
    5. Banner style
    6. Section title style
    7. Brand logo style
    8. Testimonial style
    9. Contact us style
    10. Footer style
    11. Blog style
    12. Others style
    13. Breadcrumb style
    14. Sidebar style
    15. Product details style
    16. Cart style
    17. Checkout style
    18. Wishlist style
    19. About us style
    20. 404 style
    21. My account style
    22. Login register style
    23. Compare style
    24. Blog details style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Common Style --*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.container .row {
  margin-right: -15px;
  margin-left: -15px;
}

.container .row div[class^="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.custom-container {
  max-width: 1450px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-container {
    max-width: 1300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-container {
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .custom-container {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-container {
    max-width: 540px;
  }
}

.custom-container .row {
  margin-right: -15px;
  margin-left: -15px;
}

.custom-container .row div[class^="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
  overflow-x: hidden;
  font-family: "CerebriSans";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-weight: 400;
  margin-top: 0;
  font-family: "CerebriSans";
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #444;
  margin-bottom: 15px;
  font-family: "CerebriSans";
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #333;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-180 {
  margin-bottom: 180px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

@media only screen and (max-width: 767px) {
  .pt-75 {
    padding-top: 60px;
  }
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 60px;
  }
}

.pt-95 {
  padding-top: 95px;
}

@media only screen and (max-width: 767px) {
  .pt-95 {
    padding-top: 55px;
  }
}

.pt-90 {
  padding-top: 90px;
}

@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 55px;
  }
}

.pt-100 {
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 60px;
  }
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-255 {
  padding-top: 255px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-290 {
  padding-top: 290px;
}

.pt-295 {
  padding-top: 295px;
}

.pt-280 {
  padding-top: 280px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-330 {
  padding-top: 330px;
}

.pt-340 {
  padding-top: 340px;
}

.pt-350 {
  padding-top: 350px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-360 {
  padding-top: 360px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

.pt-400 {
  padding-top: 400px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 55px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

@media only screen and (max-width: 767px) {
  .pb-75 {
    padding-bottom: 60px;
  }
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

@media only screen and (max-width: 767px) {
  .pb-95 {
    padding-bottom: 55px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 60px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-315 {
  padding-bottom: 315px;
}

.pb-330 {
  padding-bottom: 330px;
}

.pb-335 {
  padding-bottom: 335px;
}

.pb-340 {
  padding-bottom: 340px;
}

.pb-325 {
  padding-bottom: 325px;
}

.pb-320 {
  padding-bottom: 300px;
}

/*************************
    Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.custom-d-flex {
  display: flex !important;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

.justify-content-center {
  align-items: center;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.custom-common-column {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

.custom-common-column.custom-padding-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.custom-common-column.custom-column-width-66 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-common-column.custom-column-width-66 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-common-column.custom-column-width-66 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-common-column.custom-column-width-66 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.custom-common-column.custom-column-width-33 {
  flex: 0 0 33.334%;
  max-width: 33.334%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-common-column.custom-column-width-33 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-common-column.custom-column-width-33 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-common-column.custom-column-width-33 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 60px;
  height: 60px;
  background-color: #4e97fd;
  color: #ffffff;
  right: 30px;
  bottom: 30px;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 999 !important;
}

#scrollUp:hover {
  color: #ffffff;
  background-color: #e4573d;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
    right: 15px;
  }
}

#scrollUp i {
  display: block;
  line-height: 60px;
  font-size: 25px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

.height-100vh {
  height: 100vh !important;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    Background Color
------------------------------------------*/
.bg-gray {
  background-color: #f2f4f9;
}

.bg-gray-2 {
  background-color: #f8f8f8;
}

.border-bottom-1 {
  border-bottom: 1px solid #eee;
}

.border-top-1 {
  border-top: 1px solid #ededed;
}

.border-top-2 {
  border-top: 1px solid #eee;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

.default-overlay.overly-point-4:before {
  background-color: #181818;
  opacity: .4;
}

.default-overlay-white {
  position: relative;
}

.default-overlay-white:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .4;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .res-black-overly-xs {
    position: relative;
  }
  .res-black-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-20-row-col .row {
  margin-right: -10px;
  margin-left: -10px;
}

.padding-20-row-col .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-15-row-col .container, .padding-15-row-col .custom-container {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

@media only screen and (max-width: 767px) {
  .padding-15-row-col .container, .padding-15-row-col .custom-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.padding-15-row-col .container .row, .padding-15-row-col .custom-container .row {
  margin-right: -7.5px;
  margin-left: -7.5px;
}

@media only screen and (max-width: 767px) {
  .padding-15-row-col .container .row, .padding-15-row-col .custom-container .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.padding-15-row-col .container .row div[class^="col-"], .padding-15-row-col .custom-container .row div[class^="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

@media only screen and (max-width: 767px) {
  .padding-15-row-col .container .row div[class^="col-"], .padding-15-row-col .custom-container .row div[class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.col-width-25 {
  flex: 0 0 25%;
  max-width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-25 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-25 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-width-25 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-width-22 {
  flex: 0 0 22%;
  max-width: 22%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-22 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-22 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-width-22 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-width-31 {
  flex: 0 0 31%;
  max-width: 31%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-31 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-31 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-width-31 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-width-44 {
  flex: 0 0 44%;
  max-width: 44%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-44 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-44 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-width-56 {
  flex: 0 0 56%;
  max-width: 56%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-56 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-width-33 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-33 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-33 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-width-33 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-width-16 {
  flex: 0 0 16.667%;
  max-width: 16.667%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-16 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-16 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-width-16 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-width-16-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col-width-16-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-16-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-16-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .col-width-16-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-width-58 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-58 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-58 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-width-41 {
  flex: 0 0 41.667%;
  max-width: 41.667%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col-width-41 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .col-width-41 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Loop Animations */
@keyframes tm-animation-buzz-out {
  10% {
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    transform: translateX(1px) rotate(0);
  }
  100% {
    transform: translateX(-1px) rotate(0);
  }
}

@keyframes tm-loop-move-vertical {
  0% {
    transform: translate3d(0, -5px, 0);
  }
  100% {
    transform: translate3d(0, 5px, 0);
  }
}

.loop-move-vertical {
  animation: 3s tm-loop-move-vertical linear infinite alternate;
}

@keyframes tm-loop-move-horizontal {
  0% {
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    transform: translate3d(5px, 0, 0);
  }
}

.loop-move-horizontal {
  animation: 3s tm-loop-move-horizontal linear infinite alternate;
}

@keyframes tm-loop-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.loop-rotate {
  animation: 3s tm-loop-rotate linear infinite;
}

/* Hover Animations */
.medizin-animation-zoom-in .medizin-image {
  overflow: hidden;
}

.medizin-animation-zoom-in .medizin-image img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.medizin-animation-zoom-in .medizin-box:hover .medizin-image img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.medizin-animation-zoom-out .medizin-image {
  overflow: hidden;
}

.medizin-animation-zoom-out .medizin-image img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(1.1, 1.1, 1.1);
}

.medizin-animation-zoom-out .medizin-box:hover .medizin-image img {
  transform: scale3d(1, 1, 1);
}

.medizin-animation-move-up .medizin-box .medizin-image {
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.medizin-animation-move-up .medizin-box:hover .medizin-image {
  transform: translateY(-10px);
}

.medizin-animation-move-up-drop-shadow .medizin-box .medizin-image {
  position: relative;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.medizin-animation-move-up-drop-shadow .medizin-box .medizin-image:after {
  content: '';
  position: absolute;
  bottom: 0;
  top: 50%;
  left: 10%;
  right: 10%;
  box-shadow: 0 10px 50px rgba(51, 51, 51, 0.22);
  z-index: -1;
  opacity: 0;
  transition: opacity .3s linear;
}

.medizin-animation-move-up-drop-shadow .medizin-box:hover .medizin-image {
  transform: translateY(-10px);
}

.medizin-animation-move-up-drop-shadow .medizin-box:hover .medizin-image:after {
  opacity: 1;
}

/* Entrance Animations */
@keyframes medizinAnimationFadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.tmFadeInUp {
  animation-name: medizinAnimationFadeInUp;
}

.tmFadeInUp.animated {
  animation-duration: 1s;
}

/* Entrance Grid Animation */
.medizin-grid .grid-item {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}

.medizin-grid .grid-item.animate {
  transform: none;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s linear;
}

@-webkit-keyframes moveHorizontal {
  0% {
  }
  100% {
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveHorizontal {
  0% {
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

body:not(.elementor-editor-active) .modern-grid .grid-item {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}

body:not(.elementor-editor-active) .modern-grid .grid-item.animate {
  transform: none;
  opacity: 1;
  transition: transform 1s ease-in-out, opacity 1s linear;
}

/*-------- 3. Header style ---------*/
.header-top-ptb-1 {
  padding: 7px 0;
}

.header-info-right {
  display: flex;
  justify-content: flex-end;
}

.header-info > ul {
  display: flex;
}

.header-info > ul > li {
  margin-right: 30px;
  position: relative;
  display: flex;
  align-items: center;
}

.header-info > ul > li:before {
  content: '';
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 20px;
  background: #dedfe2;
}

.header-info > ul > li:last-child {
  margin-right: 0;
}

.header-info > ul > li:last-child:before {
  display: none;
}

.header-info > ul > li a {
  color: #444;
  font-weight: 500;
}

.header-info > ul > li a i {
  font-size: 14px;
  margin-right: 5px;
}

.header-info > ul > li a.language-dropdown-active i {
  font-size: 8px;
  margin-left: 5px;
}

.header-info > ul > li > ul.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  min-width: 200px;
  background: #fff;
  border-radius: 5px;
  transform: translateY(20px);
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  visibility: hidden;
  opacity: 0;
  padding: 20px 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.header-info > ul > li > ul li {
  display: block;
}

.header-info > ul > li > ul li a {
  display: block;
  color: #696969;
  padding: 11px 20px 12px;
}

.header-info > ul > li > ul li a:hover {
  color: #4e97fd;
  background-color: rgba(78, 151, 253, 0.1);
}

.header-info > ul > li:hover > a {
  color: #333;
}

.header-info > ul > li:hover > ul.language-dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.covid-update p {
  color: #444;
}

.covid-update p a {
  background-color: #3751ee;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  margin: 0 10px 0 0;
  border-radius: 5px;
  padding: 1px 12px;
}

.covid-update p a:hover {
  background-color: #e4573d;
}

.covid-update.covid-update-mobile {
  padding: 0px 0 23px;
}

.covid-update.covid-update-mobile p {
  color: #fff;
}

.header-wrap {
  display: flex;
  align-items: center;
}

.header-wrap.header-space-between {
  justify-content: space-between;
}

.logo a {
  display: block;
}

.logo.logo-width-1 a img {
  width: 166px;
}

@media only screen and (max-width: 767px) {
  .logo.logo-width-1 a img {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo.logo-hm3 a img {
    width: 120px;
  }
}

.main-menu.main-menu-grow {
  flex-grow: 1;
}

.main-menu.main-menu-mrg-1 {
  margin: 0 0 0 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-mrg-1 {
    margin: 0 0 0 15px;
  }
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li.position-static {
  position: static;
}

.main-menu > nav > ul > li > a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #444;
}

.main-menu > nav > ul > li > a i {
  font-size: 8px;
  position: relative;
  top: -3px;
  margin-left: 4px;
}

.main-menu > nav > ul > li > a.active {
  color: #4e97fd;
}

.main-menu > nav > ul > li ul.sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 275px;
  padding: 33px 0 35px;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  border-radius: 5px;
  z-index: 999;
}

.main-menu > nav > ul > li ul.sub-menu li {
  line-height: 1;
  display: block;
  margin-bottom: 21px;
  position: relative;
}

.main-menu > nav > ul > li ul.sub-menu li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu {
  position: absolute;
  left: 100%;
  top: -110px;
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 240px;
  padding: 33px 0 35px;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
  margin-top: 20px;
}

.main-menu > nav > ul > li ul.sub-menu li ul.level-menu.level-menu-modify {
  top: -25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.sub-menu li ul.level-menu.level-menu-modify {
    width: 165px;
  }
}

.main-menu > nav > ul > li ul.sub-menu li a {
  font-size: 16px;
  color: #555;
  display: block;
  padding: 0 30px;
}

.main-menu > nav > ul > li ul.sub-menu li a i {
  font-size: 9px;
  float: right;
  position: relative;
  top: 4px;
}

.main-menu > nav > ul > li ul.sub-menu li:hover > a {
  color: #000;
}

.main-menu > nav > ul > li ul.sub-menu li:hover ul.level-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.main-menu > nav > ul > li ul.mega-menu {
  position: absolute;
  left: 0;
  top: 100%;
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  width: 100%;
  padding: 35px 25px 35px 35px;
  transition: all .25s ease 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: 20px;
  border-radius: 5px;
  z-index: 999;
}

.main-menu > nav > ul > li ul.mega-menu li {
  line-height: 1;
  display: block;
  position: relative;
  float: left;
  padding-right: 10px;
}

.main-menu > nav > ul > li ul.mega-menu li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-22 {
  width: 22%;
}

.main-menu > nav > ul > li ul.mega-menu li.sub-mega-menu-width-34 {
  width: 34%;
}

.main-menu > nav > ul > li ul.mega-menu li a.menu-title {
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.main-menu > nav > ul > li ul.mega-menu li ul {
  margin-top: 28px;
}

.main-menu > nav > ul > li ul.mega-menu li ul li {
  line-height: 1;
  display: block;
  margin-bottom: 15px;
}

.main-menu > nav > ul > li ul.mega-menu li ul li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li ul.mega-menu li ul li a {
  font-size: 16px;
  color: #555;
  display: block;
  line-height: 1.4;
}

.main-menu > nav > ul > li ul.mega-menu li ul li a:hover {
  color: #000;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap {
  overflow: hidden;
  position: relative;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap a {
  display: block;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap a img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content {
  position: absolute;
  top: 32px;
  left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content {
    left: 20px;
    top: 22px;
  }
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h4 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin: 5px 0 11px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content h3 {
    font-size: 20px;
    line-height: 1.3;
    margin: 5px 0 7px;
  }
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price {
  margin: 0 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price {
    margin: 0 0 10px;
  }
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price span.new-price {
  font-size: 24px;
  font-weight: 700;
  color: #e4573d;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-price span.old-price {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
  text-decoration: line-through;
  margin-left: 5px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-btn a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 12px 22px 15px;
  background-color: #4e97fd;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-content .menu-banner-btn a {
    padding: 10px 16px 13px;
  }
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd55a;
  position: absolute;
  top: 13%;
  right: 7%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap .menu-banner-discount h3 span {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 3px;
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap:hover a img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.main-menu > nav > ul > li ul.mega-menu li .menu-banner-wrap:hover .menu-banner-btn a {
  background-color: #e4573d;
}

.main-menu > nav > ul > li:hover > a {
  color: #4e97fd;
}

.main-menu > nav > ul > li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-menu > nav > ul > li:hover ul.mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.main-menu.hover-boder > nav > ul > li > a {
  position: relative;
}

.main-menu.hover-boder > nav > ul > li > a::after {
  content: '';
  position: absolute;
  left: auto;
  right: 0;
  bottom: 34px;
  height: 1px;
  width: 0;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #4e97fd;
}

.main-menu.hover-boder > nav > ul > li:hover > a::after {
  width: 100%;
  left: 0;
  right: auto;
}

.main-menu.hover-boder.hover-boder-white > nav > ul > li > a::after {
  bottom: 18px;
  background: #ffffff;
}

.main-menu.hover-boder.hover-boder-modify > nav > ul > li > a::after {
  bottom: 28px;
}

.main-menu.main-menu-light-white > nav > ul > li > a {
  color: white;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding: 0 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding: 0 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-1.hm3-menu-padding > nav > ul > li {
    padding: 0 6px;
  }
}

.main-menu.main-menu-lh-1 > nav > ul > li {
  line-height: 92px;
}

.main-menu.main-menu-lh-2 > nav > ul > li {
  line-height: 60px;
}

.main-menu.main-menu-lh-3 > nav > ul > li {
  line-height: 80px;
}

.header-action-right {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  align-items: center;
}

.search-style-1 {
  margin-right: 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-1 {
    margin-right: 15px;
  }
}

.search-style-1 form {
  width: 370px;
  position: relative;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .search-style-1 form {
    width: 280px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-style-1 form {
    width: 220px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-1 form {
    width: 170px;
  }
}

.search-style-1 form input {
  font-size: 15px;
  height: 48px;
  color: #444;
  border-radius: 26px;
  padding: 3px 50px 3px 20px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-style-1 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-1 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-1 form input:focus {
  border: 1px solid #4e97fd;
  background-color: #ffffff;
}

.search-style-1 form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 16px;
  height: 100%;
  padding: 0 15px;
  background-color: transparent;
  color: #4e97fd;
}

.search-style-1 form button:hover {
  color: #e4573d;
}

.header-action {
  display: flex;
  align-items: center;
}

.header-action .header-action-icon {
  margin-right: 28px;
  position: relative;
  display: flex;
}

.header-action .header-action-icon.header-action-mrg-none {
  margin-right: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .header-action-icon.header-action-mrg-none {
    margin-right: 20px;
  }
}

.header-action .header-action-icon.header-action-mrg-none2 {
  margin-right: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .header-action-icon.header-action-mrg-none2 {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action .header-action-icon {
    margin-right: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action .header-action-icon {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .header-action-icon {
    margin-right: 20px;
  }
}

.header-action .header-action-icon:last-child {
  margin-right: 0;
}

.header-action .header-action-icon > a {
  font-size: 24px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .header-action .header-action-icon > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .header-action-icon > a {
    font-size: 22px;
  }
}

.header-action .header-action-icon > a span.pro-count {
  position: absolute;
  right: -11px;
  bottom: -8px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}

.header-action .header-action-icon > a span.pro-count.blue {
  background-color: #4e97fd;
}

.header-action .header-action-icon:hover > a {
  color: #4e97fd;
}

.header-action .header-action-icon:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action.header-action-hm3 .header-action-icon {
    margin-right: 15px;
  }
  .header-action.header-action-hm3 .header-action-icon.header-action-mrg-none2 {
    margin-right: 0px;
  }
  .header-action.header-action-hm3 .header-action-icon:last-child {
    margin-right: 0;
  }
}

.header-action-2 {
  display: flex;
  align-items: center;
}

.header-action-2 .header-action-icon-2 {
  position: relative;
  display: flex;
  padding: 0 14px;
}

@media only screen and (max-width: 767px) {
  .header-action-2 .header-action-icon-2 {
    padding: 0 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action-2 .header-action-icon-2 {
    padding: 0 10px;
  }
}

.header-action-2 .header-action-icon-2:last-child {
  padding: 0 0px 0 14px;
}

@media only screen and (max-width: 767px) {
  .header-action-2 .header-action-icon-2:last-child {
    padding: 0 0px 0 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action-2 .header-action-icon-2:last-child {
    padding: 0 0px 0 10px;
  }
}

.header-action-2 .header-action-icon-2 > a {
  font-size: 30px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
  width: 1em;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-action-2 .header-action-icon-2 > a {
    color: #ffffff;
    fill: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .header-action-2 .header-action-icon-2 > a {
    color: #ffffff;
    fill: #ffffff;
  }
}

.header-action-2 .header-action-icon-2 > a img {
  width: 100%;
}

.header-action-2 .header-action-icon-2 > a svg {
  width: 1em;
  height: 1em;
}

@media only screen and (max-width: 767px) {
  .header-action-2 .header-action-icon-2 > a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action-2 .header-action-icon-2 > a {
    font-size: 22px;
  }
}

.header-action-2 .header-action-icon-2 > a span.pro-count {
  position: absolute;
  right: -11px;
  bottom: -8px;
  color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}

.header-action-2 .header-action-icon-2 > a span.pro-count.blue {
  background-color: #4e97fd;
}

.header-action-2 .header-action-icon-2:hover > a {
  color: #4e97fd;
  fill: #4e97fd;
}

.header-action-2 .header-action-icon-2:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-middle-ptb-1 {
  padding: 21px 0 27px;
}

.cart-dropdown-wrap {
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 99;
  width: 360px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  padding: 30px 20px 27px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (max-width: 767px) {
  .cart-dropdown-wrap {
    width: 290px;
    right: -39px;
  }
  .cart-dropdown-wrap.cart-dropdown-hm2 {
    right: -63px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-dropdown-wrap {
    width: 310px;
  }
}

.cart-dropdown-wrap ul li {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 20px;
}

.cart-dropdown-wrap ul li:last-child {
  margin: 0 0 0px;
}

.cart-dropdown-wrap ul li .shopping-cart-img {
  flex: 0 0 80px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .cart-dropdown-wrap ul li .shopping-cart-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
}

.cart-dropdown-wrap ul li .shopping-cart-img a {
  display: block;
}

.cart-dropdown-wrap ul li .shopping-cart-img a img {
  max-width: 100%;
}

.cart-dropdown-wrap ul li .shopping-cart-title {
  margin: 6px 0 0;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 9px;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 a {
  color: #696969;
}

.cart-dropdown-wrap ul li .shopping-cart-title h4 a:hover {
  color: #4e97fd;
}

.cart-dropdown-wrap ul li .shopping-cart-title h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 0px;
  color: #4e97fd;
}

.cart-dropdown-wrap ul li .shopping-cart-title h3 span {
  color: #696969;
  font-weight: 400;
  font-size: 15px;
}

.cart-dropdown-wrap ul li .shopping-cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
  margin: 6px 0 0;
}

.cart-dropdown-wrap ul li .shopping-cart-delete a {
  font-size: 18px;
  color: #696969;
}

.cart-dropdown-wrap ul li .shopping-cart-delete a:hover {
  color: #333;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total {
  border-top: 2px solid #f3f3f3;
  margin: 27px 0 20px;
  padding: 17px 0 0;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 {
  color: #9b9b9b;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-total h4 span {
  font-size: 18px;
  float: right;
  color: #4e97fd;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50px;
  line-height: 1;
  padding: 16px 42px 17px;
  background-color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a {
    padding: 12px 22px 13px;
  }
}

.cart-dropdown-wrap .shopping-cart-footer .shopping-cart-button a:hover {
  background-color: #e4573d;
}

.sticky-bar.stick {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  transition: all .3s ease 0s;
}

.sticky-bar.stick.sticky-white-bg {
  background-color: #fff;
}

.sticky-bar.stick.sticky-blue-bg {
  background-color: #415da1;
}

.header-height-1 {
  min-height: 133px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-height-1 {
    min-height: 157px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-1 {
    min-height: 83px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-1 {
    min-height: 65px;
  }
}

.header-height-2 {
  min-height: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-height-2 {
    min-height: 224px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-2 {
    min-height: 83px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-2 {
    min-height: 65px;
  }
}

.header-height-3 {
  min-height: 132px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-height-3 {
    min-height: 133px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-3 {
    min-height: 83px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-3 {
    min-height: 65px;
  }
}

.header-height-4 {
  min-height: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-height-4 {
    min-height: 144px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-height-4 {
    min-height: 83px;
  }
}

@media only screen and (max-width: 767px) {
  .header-height-4 {
    min-height: 65px;
  }
}

.search-style-2 form {
  display: flex;
  position: relative;
}

.search-style-2 form select {
  color: #444;
  font-size: 15px;
  min-height: 52px;
  border: 2px solid #eee;
  border-right: none;
  width: 170px;
  padding: 3px 35px 3px 20px;
  border-radius: 26px 0 0 26px;
  background: transparent url("../../assets/images/icon-img/select.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  transition: all .3s ease 0s;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.search-style-2 form select:focus {
  color: #333;
}

.search-style-2 form input {
  color: #444;
  font-size: 15px;
  min-height: 52px;
  border: 2px solid #eee;
  width: 540px;
  padding: 3px 62px 3px 20px;
  border-radius: 0px 26px 26px 0px;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-style-2 form input {
    width: 340px;
  }
}

.search-style-2 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-2 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-2 form input:focus {
  border: 2px solid #4e97fd;
}

.search-style-2 form button {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: 20px;
  color: #4e97fd;
  height: 100%;
  padding: 5px 17px;
}

.header-bottom-bg-color {
  background-color: #415da1;
}

.main-categori-wrap {
  position: relative;
}

.main-categori-wrap > a {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 700;
}

.main-categori-wrap > a span {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .main-categori-wrap > a {
    font-weight: 500;
  }
}

.main-categori-wrap > a > i {
  font-size: 14px;
  margin-left: 15px;
  font-weight: 300;
}

.main-categori-wrap > a > i.up {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.main-categori-wrap > a.open > i {
  opacity: 0;
  visibility: hidden;
}

.main-categori-wrap > a.open > i.up {
  opacity: 1;
  visibility: visible;
}

.hotline p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hotline p {
    font-size: 15px;
  }
}

.hotline p i {
  font-size: 24px;
  margin-right: 10px;
  color: #4e97fd;
}

.hotline p span {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}

.categori-dropdown-active-large {
  list-style-type: none;
  position: absolute;
  top: 177%;
  left: 0;
  z-index: 9;
  margin: 0;
  padding: 15px 0 23px;
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  font-size: 15px;
  font-weight: 400;
  min-width: 270px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  visibility: hidden;
}

.categori-dropdown-active-large.open {
  opacity: 1;
  visibility: visible;
}

.categori-dropdown-active-small {
  z-index: 9;
  margin: 0;
  padding: 15px 0 23px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 -3px 23px rgba(0, 0, 0, 0.06);
  font-size: 15px;
  font-weight: 400;
  display: none;
  width: 100%;
}

.categori-dropdown-wrap ul li {
  display: block;
}

.categori-dropdown-wrap ul li a {
  display: flex;
  align-items: center;
  padding: 5px 34px;
  line-height: 1.5;
  color: #444;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .categori-dropdown-wrap ul li a {
    padding: 5px 12px;
  }
}

.categori-dropdown-wrap ul li a i {
  margin-right: 16px;
  font-size: 20px;
}

.categori-dropdown-wrap ul li a:hover {
  color: #4e97fd;
  background-color: rgba(78, 151, 253, 0.1);
}

.header-bottom-shadow {
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.05);
}

.search-style-3 form {
  position: relative;
}

.search-style-3 form input {
  font-size: 15px;
  height: 40px;
  color: #444;
  border-radius: 26px;
  padding: 3px 50px 3px 20px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-style-3 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-3 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.search-style-3 form input:focus {
  border: 1px solid #4e97fd;
}

.search-style-3 form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border: none;
  font-size: 14px;
  height: 100%;
  padding: 0 14px;
  background-color: transparent;
  color: #333;
}

.search-style-3 form button:hover {
  color: #4e97fd;
}

.search-popup-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: white;
  transition: all .5s ease-in-out;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-popup-wrap .search-popup-content form input {
  width: 1170px;
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid #4e97fd;
  font-size: 50px;
  padding: 21px 50px 16px 0px;
  color: #919191;
  font-weight: 300;
  max-width: 100%;
  height: auto;
  transform: translate(0, 50px);
  transition-delay: 0s;
  opacity: 0;
  transition: all .5s ease-in-out,opacity .5s linear;
}

.search-popup-wrap .search-popup-content form input::-moz-input-placeholder {
  color: #919191;
  opacity: 1;
}

.search-popup-wrap .search-popup-content form input::-webkit-input-placeholder {
  color: #919191;
  opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .search-popup-wrap .search-popup-content form input {
    width: 1000px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-popup-wrap .search-popup-content form input {
    width: 820px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-popup-wrap .search-popup-content form input {
    width: 650px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .search-popup-wrap .search-popup-content form input {
    width: 270px;
    font-size: 25px;
    padding: 21px 20px 12px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search-popup-wrap .search-popup-content form input {
    width: 480px;
    font-size: 25px;
    padding: 21px 20px 12px 0px;
  }
}

.search-popup-wrap.search-visible {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.search-popup-wrap.search-visible .search-popup-content form input {
  transform: none;
  opacity: 1;
  transition-delay: .6s;
}

.close-style-wrap {
  position: absolute;
  right: 55px;
  top: 40px;
}

.close-style-wrap.close-style-position-inherit {
  position: inherit;
}

.close-style-wrap .close-style {
  position: relative;
  background-color: transparent;
  padding: 0;
  border: none;
  width: 40px;
  height: 40px;
}

.close-style-wrap .close-style > i {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 29px;
  height: 2px;
  margin: 0 !important;
  transform-origin: center;
  display: block;
  overflow: hidden;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.close-style-wrap .close-style > i::before, .close-style-wrap .close-style > i::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #111;
}

.close-style-wrap .close-style > i.icon-top {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-style-wrap .close-style > i.icon-top::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
  transform-origin: right;
}

.close-style-wrap .close-style > i.icon-top::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
  transform-origin: left;
}

.close-style-wrap .close-style > i.icon-bottom {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.close-style-wrap .close-style > i.icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
  transform-origin: right;
}

.close-style-wrap .close-style > i.icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
  transform-origin: left;
}

.close-style-wrap .close-style:hover i.icon-top::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
}

.close-style-wrap .close-style:hover i.icon-top::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
}

.close-style-wrap .close-style:hover i.icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
}

.close-style-wrap .close-style:hover i.icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
}

.burger-icon {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.burger-icon > span {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-icon > span::before, .burger-icon > span::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #333;
}

.burger-icon > span.burger-icon-top {
  top: 5px;
}

.burger-icon > span.burger-icon-top::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
  transform-origin: right;
}

.burger-icon > span.burger-icon-top::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
  transform-origin: left;
}

.burger-icon > span.burger-icon-bottom {
  bottom: 5px;
}

.burger-icon > span.burger-icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
  transform-origin: right;
}

.burger-icon > span.burger-icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
  transform-origin: left;
}

.burger-icon.burger-icon-white > span::before, .burger-icon.burger-icon-white > span::after {
  background-color: #ffffff;
}

.burger-icon:hover span::before, .burger-icon:hover span::after {
  background-color: #4e97fd;
}

.burger-icon:hover span.burger-icon-top::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
}

.burger-icon:hover span.burger-icon-top::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
}

.burger-icon:hover span.burger-icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
}

.burger-icon:hover span.burger-icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
}

.body-overlay-1 {
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 9999;
}

.mobile-menu-active .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.main-wrapper {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-header-wrapper-style {
  position: fixed;
  top: 0;
  width: 360px;
  min-height: 100vh;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translate(200px, 0);
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 99999;
}

@media only screen and (max-width: 767px) {
  .mobile-header-wrapper-style {
    width: 300px;
  }
}

.mobile-header-wrapper-style.sidebar-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner {
  padding: 0px 0px 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #4e97fd;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 30px;
  background-color: #ffffff;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top {
    padding: 20px 20px;
  }
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a {
  display: block;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-top .mobile-header-logo a img {
  width: 166px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
  padding: 30px 30px 30px;
}

@media only screen and (max-width: 767px) {
  .mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area {
    padding: 30px 20px 30px;
  }
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav {
  height: 100%;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
  padding: 18px 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand {
  top: 10px;
  right: 0%;
  position: absolute;
  cursor: pointer;
  z-index: 9;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100%;
  background-color: transparent;
  color: #ffffff;
  line-height: 40px;
  font-size: 18px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  content: "\f106";
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  display: inline-block;
  color: #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul {
  padding: 18px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 20px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li {
  padding: 10px 0 12px;
  border-bottom: none;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li.menu-item-has-children .menu-expand {
  top: 0px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  display: block;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul {
  margin-top: 32px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap {
  padding: 25px 0 30px;
  margin-bottom: 30px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 16px;
  display: block;
  color: #ffffff;
  font-weight: 500;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a span {
  float: right;
  font-size: 18px;
  position: relative;
  top: 8px;
  line-height: 1;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 15px;
  color: #696969;
  font-weight: 400;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #4e97fd;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: rgba(255, 255, 255, 0.7);
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a {
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
  font-size: 15px;
  margin-right: 10px;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.facebook {
  background-color: #3b5999;
  border: 1px solid #3b5999;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.facebook:hover {
  color: #333;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.twitter:hover {
  color: #333;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.tumblr {
  background-color: #35465c;
  border: 1px solid #35465c;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.tumblr:hover {
  color: #333;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.instagram {
  background-color: #e4405f;
  border: 1px solid #e4405f;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-social-icon a.instagram:hover {
  color: #333;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}

.mobile-header-wrapper-style .mobile-header-wrapper-inner .mobile-header-content-area .mobile-header-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-search {
  padding: 30px 0 30px;
}

.categori-button-active-2 {
  padding: 21px 0;
  display: block !important;
}

.categori-button-active-2 i {
  float: right;
  position: relative;
  top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-bottom {
    padding: 25px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-bottom {
    padding: 20px 0;
  }
}

/*-------- 4. Slider style ---------*/
.slider-height-1 {
  height: 574px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-1 {
    height: 514px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-1 {
    height: 474px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 510px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: 474px;
    background-position: 60%;
  }
}

.slider-height-2 {
  height: 620px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-2 {
    height: 580px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 520px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 470px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 700px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-2 {
    height: 450px;
  }
}

.slider-height-3 {
  height: 620px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    height: 580px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    height: 520px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    height: 470px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: 650px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-3 {
    height: 450px;
  }
}

.single-hero-slider {
  position: relative;
}

.slider-product-price {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd55a;
}

@media only screen and (max-width: 767px) {
  .slider-product-price {
    width: 75px;
    height: 75px;
    line-height: 75px;
  }
}

.slider-product-price h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider-product-price h3 {
    font-size: 22px;
  }
}

.slider-product-price h3 span {
  display: block;
  line-height: 1;
  font-weight: 400;
  font-size: 20px;
  margin: 0 0 5px;
}

@media only screen and (max-width: 767px) {
  .slider-product-price h3 span {
    font-size: 16px;
  }
}

.slider-product-price h3 span.mrg-top {
  margin: 6px 0 0px;
}

@media only screen and (max-width: 767px) {
  .slider-product-price h3 span.mrg-top {
    margin: 2px 0 0px;
  }
}

.slider-product-price.slider-product-position1 {
  position: absolute;
  top: 21%;
  left: 44%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-product-price.slider-product-position1 {
    top: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-product-price.slider-product-position1 {
    top: 12%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-product-price.slider-product-position1 {
    top: 10%;
  }
}

.slider-product-price.slider-product-position2 {
  position: absolute;
  top: 21%;
  right: 7%;
}

@media only screen and (max-width: 767px) {
  .slider-product-price.slider-product-position2 {
    top: 6%;
  }
}

.slider-product-price.slider-product-position3 {
  position: absolute;
  top: 24%;
  right: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-product-price.slider-product-position3 {
    right: 23%;
  }
}

.slider-product-price.slider-product-position4 {
  position: absolute;
  top: 23%;
  right: 66%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-product-price.slider-product-position4 {
    right: 32%;
  }
}

.slider-product-price.slider-product-position5 {
  position: absolute;
  top: 19%;
  right: 0%;
}

.slider-product-price.slider-product-position6 {
  position: absolute;
  top: 19%;
  right: 51%;
}

.slider-product-price.slider-product-position7 {
  position: absolute;
  top: 24%;
  right: 50%;
}

.hero-slider-content-1 {
  padding: 0 60px 50px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 {
    padding: 0 15px 50px;
  }
}

.hero-slider-content-1 h1 {
  font-size: 34px;
  line-height: 48px;
  font-weight: 500;
  margin: 0;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 h1 {
    font-size: 25px;
    line-height: 34px;
  }
}

.hero-slider-content-1 > p {
  font-size: 15px;
  line-height: 26px;
  margin: 19px 0 27px;
  color: #ffffff;
  width: 60%;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 > p {
    width: 100%;
    margin: 15px 0 20px;
  }
}

.hero-slider-content-1 h4 {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 3px;
  color: #e4573d;
  text-transform: uppercase;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 h4 {
    margin: 0 0 15px;
  }
}

.hero-slider-content-1 .slider-product-offer-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.hero-slider-content-1 .slider-product-offer-wrap .slider-product-offer {
  display: flex;
}

.hero-slider-content-1 .slider-product-offer-wrap .slider-product-offer span {
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 2px;
  margin-right: 10px;
  padding: 6px 10px;
}

.hero-slider-content-1 .slider-product-offer-wrap .slider-product-offer span:last-child {
  margin-right: 0;
}

.hero-slider-content-1 .slider-product-offer-wrap .slider-product-offer span.yellow {
  background-color: #f6b500;
}

.hero-slider-content-1 .slider-product-offer-wrap .slider-product-offer span.red {
  background-color: #e4573d;
}

.hero-slider-content-1 .slider-product-offer-wrap p {
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 0 10px;
}

.hero-slider-content-1.slider-content-1-black h1 {
  color: #333;
}

.hero-slider-content-1.slider-content-1-black p {
  color: #333;
}

.btn-style-1 a {
  display: inline-block;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50px;
  padding: 14px 42px 16px;
  background-color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a {
    padding: 10px 32px 12px;
  }
}

.btn-style-1 a.font-size-14 {
  font-size: 14px;
}

.btn-style-1 a.btn-1-padding-2 {
  padding: 8px 22px 9px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-2 {
    padding: 4px 15px 7px;
  }
}

.btn-style-1 a.btn-1-padding-3 {
  padding: 14px 34px 16px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-3 {
    padding: 10px 32px 12px;
  }
}

.btn-style-1 a.btn-1-padding-4 {
  padding: 7px 36px 8px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-4 {
    padding: 4px 15px 7px;
  }
}

.btn-style-1 a.btn-1-padding-5 {
  padding: 14px 54px 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a.btn-1-padding-5 {
    padding: 12px 44px 12px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-5 {
    padding: 7px 30px 10px;
  }
}

.btn-style-1 a:hover {
  background-color: #e4573d;
}

.nav-style-1.nav-style-1-position-1 > span {
  position: absolute;
  bottom: 7%;
  right: 6%;
}

@media only screen and (max-width: 767px) {
  .nav-style-1.nav-style-1-position-1 > span {
    bottom: 3%;
  }
}

.nav-style-1 > span {
  cursor: pointer;
  color: #ffffff;
  width: 52px;
  height: 52px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9;
  font-size: 18px;
}

.nav-style-1 > span i {
  line-height: 52px;
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span i {
    line-height: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span {
    width: 42px;
    height: 42px;
    font-size: 16px;
  }
}

.nav-style-1 > span:hover {
  background: rgba(0, 0, 0, 0.5);
}

.nav-style-1 > span.slider-icon-1-prev {
  margin-right: 62px;
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span.slider-icon-1-prev {
    margin-right: 52px;
  }
}

.single-animation-wrap.slick-active .slider-animated-1 span {
  animation-delay: .5s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h4 {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h3 {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h1 {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 p {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .btn-style-1 a {
  animation-delay: 2.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1.slider-product-price {
  animation-delay: 1.2s;
  animation-name: flipInY;
}

.single-animation-wrap.slick-active .slider-animated-1 .slider-product-offer-wrap,
.single-animation-wrap.slick-active .slider-animated-1 .slider-product-offer-wrap-2 {
  animation-delay: 1.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .single-slider-img img {
  animation-delay: 1.5s;
  animation-name: fadeInUp;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-2 {
    text-align: left;
  }
}

.hero-slider-content-2 h4 {
  font-size: 24px;
  color: #e4573d;
  margin: 0 0 18px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 h4 {
    margin: 0 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 h4 {
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-2 h4 {
    font-size: 22px;
  }
}

.hero-slider-content-2 h1 {
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  color: #333;
  margin: 0 0 0px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-2 h1 {
    font-size: 44px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 h1 {
    font-size: 44px;
    line-height: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 h1 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 h1 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-2 h1 {
    font-size: 24px;
    line-height: 32px;
  }
}

.hero-slider-content-2 h1.mrg-bottom {
  margin: 0 0 23px;
}

.hero-slider-content-2 p {
  font-size: 18px;
  line-height: 36px;
  color: #333;
  margin: 7px 0 22px;
  width: 74%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-2 p {
    width: 83%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-2 p {
    width: 88%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 p {
    width: 88%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 p {
    width: 100%;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 p {
    width: 100%;
    font-size: 17px;
    line-height: 30px;
    margin: 5px 0 15px;
  }
}

.hero-slider-content-2 span {
  display: block;
  font-size: 15px;
  color: #333;
  line-height: 1;
}

.hero-slider-content-2 h3 {
  font-size: 34px;
  font-weight: 700;
  color: #e4573d;
  margin: 10px 0 15px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 h3 {
    font-size: 28px;
    margin: 10px 0 10px;
  }
}

.single-slider-img {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .single-slider-img {
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-slider-img {
    justify-content: flex-end;
    margin-top: 0px;
  }
}

.single-slider-img.single-slider-img-1 img {
  width: 350px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-img.single-slider-img-1 img {
    width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-img.single-slider-img-1 img {
    width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-1 img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-1 img {
    width: 200px;
  }
}

.single-slider-img.single-slider-img-2 img {
  width: 543px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-img.single-slider-img-2 img {
    width: 343px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-img.single-slider-img-2 img {
    width: 343px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-2 img {
    width: 243px;
  }
}

.single-slider-img.single-slider-img-3 img {
  width: 580px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-img.single-slider-img-3 img {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-img.single-slider-img-3 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-3 img {
    width: 300px;
  }
}

.single-slider-img.single-slider-img-4 img {
  width: 554px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-img.single-slider-img-4 img {
    width: 354px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-img.single-slider-img-4 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-4 img {
    width: 220px;
  }
}

.single-slider-img.single-slider-img-5 img {
  width: 495px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-img.single-slider-img-5 img {
    width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-img.single-slider-img-5 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-5 img {
    width: 260px;
  }
}

.single-slider-img.single-slider-img-6 img {
  width: 584px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-slider-img.single-slider-img-6 img {
    width: 384px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-slider-img.single-slider-img-6 img {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider-img.single-slider-img-6 img {
    width: 270px;
  }
}

.dot-style-1 ul {
  display: flex;
}

.dot-style-1 ul li {
  margin: 0 7px;
}

.dot-style-1 ul li button {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: none;
  padding: 0;
  font-size: 0px;
  background-color: transparent;
  background: rgba(51, 51, 51, 0.3);
  transition: all 0.3s linear;
  transform: translate(6px, 6px);
}

.dot-style-1 ul li button:hover {
  width: 12px;
  height: 12px;
  background: #333;
  transform: translate(4px, 4px);
}

.dot-style-1 ul li.slick-active button {
  width: 12px;
  height: 12px;
  background: #333;
  transform: translate(4px, 4px);
}

.dot-style-1.dot-style-1-position-1 ul {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;
}

.dot-style-1.dot-style-1-center ul {
  justify-content: center;
}

.dot-style-1.dot-style-1-mt1 ul {
  margin-top: 30px;
}

.hm3-bg-color-1 {
  background: rgba(0, 0, 0, 0) linear-gradient(144deg, #e2eef9 0%, #e7dfef 100%) repeat scroll 0% 0%;
}

.hm3-bg-color-3 {
  background: #e3d4c2 none repeat scroll 0% 0%;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-3 {
    text-align: left;
  }
}

.hero-slider-content-3 h4 {
  font-size: 24px;
  color: #e4573d;
  margin: 0 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 h4 {
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 h4 {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h4 {
    font-size: 20px;
    margin: 0 0 10px;
  }
}

.hero-slider-content-3 h4 span {
  font-weight: 700;
}

.hero-slider-content-3 h1 {
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 h1 {
    font-size: 30px;
    line-height: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h1 {
    font-size: 25px;
    line-height: 34px;
  }
}

.hero-slider-content-3 h1.font-inc {
  font-size: 56px;
  line-height: 64px;
  margin: 0 0 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 h1.font-inc {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 h1.font-inc {
    font-size: 33px;
    line-height: 40px;
    margin: 0 0 17px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h1.font-inc {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 7px;
  }
}

.hero-slider-content-3 p {
  font-size: 18px;
  color: #444;
  line-height: 30px;
  margin: 10px 0 30px;
  width: 75%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 p {
    width: 100%;
    margin: 10px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 p {
    width: 100%;
    margin: 7px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 p {
    width: 100%;
    font-size: 17px;
    line-height: 28px;
    margin: 7px 0 15px;
  }
}

.hero-slider-content-3 > span {
  display: block;
  font-size: 15px;
  color: #333;
  line-height: 1;
}

.hero-slider-content-3 h3 {
  font-size: 34px;
  font-weight: 700;
  color: #e4573d;
  margin: 10px 0 15px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 h3 {
    font-size: 30px;
    margin: 10px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h3 {
    font-size: 28px;
    margin: 10px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-4 {
    text-align: left;
  }
}

.hero-slider-content-4 .slider-product-offer-wrap-2 {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 .slider-product-offer-wrap-2 {
    margin: 0 0 10px;
    display: block;
  }
}

.hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 {
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 {
    justify-content: flex-start;
  }
}

.hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 span {
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  border-radius: 2px;
  margin-right: 10px;
  padding: 6px 10px;
}

.hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 span:last-child {
  margin-right: 0;
}

.hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 span.yellow {
  background-color: #f6b500;
}

.hero-slider-content-4 .slider-product-offer-wrap-2 .slider-product-offer-2 span.red {
  background-color: #e4573d;
}

.hero-slider-content-4 .slider-product-offer-wrap-2 p {
  font-size: 24px;
  color: #333;
  margin: 0 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 .slider-product-offer-wrap-2 p {
    font-size: 18px;
    margin: 5px 0 0 0px;
  }
}

.hero-slider-content-4 h1 {
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-4 h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-4 h1 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 h1 {
    font-size: 22px;
    line-height: 30px;
  }
}

.hero-slider-content-4 p {
  font-size: 18px;
  color: #333;
  line-height: 30px;
  margin: 10px 0 30px;
  width: 75%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-4 p {
    width: 89%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-4 p {
    width: 96%;
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 p {
    width: 100%;
    font-size: 16px;
    line-height: 27px;
    margin: 10px 0 20px;
  }
}

/*-------- 5. Banner style ---------*/
.banner-wrap {
  position: relative;
}

.banner-wrap .banner-img {
  position: relative;
  overflow: hidden;
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
}

.banner-wrap .banner-img-zoom a {
  display: block;
}

.banner-wrap .banner-img-zoom a img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.banner-wrap .banner-content-1 {
  position: absolute;
  left: 30px;
  top: 11%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 {
    left: 20px;
    top: 10%;
  }
}

.banner-wrap .banner-content-1 span {
  display: block;
  font-size: 15px;
  text-transform: uppercase;
}

.banner-wrap .banner-content-1 h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 12px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 20px;
    margin: 4px 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 20px;
    margin: 4px 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 20px;
    margin: 5px 0 7px;
  }
}

.banner-wrap .banner-content-1 h3 {
  font-size: 24px;
  font-weight: bold;
  color: #e4573d;
  margin: 0px 0 26px;
}

.banner-wrap .banner-content-1 h3 span {
  color: #696969;
  margin-left: 2px;
  display: inline-block;
  font-size: 14px;
  text-decoration: line-through;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 22px;
    margin: 0px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 20px;
    margin: 0px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h3 {
    font-size: 20px;
    margin: 0px 0 12px;
  }
}

.banner-wrap .banner-content-2 {
  position: absolute;
  left: 30px;
  top: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 {
    top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 {
    top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    left: 20px;
    top: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    top: 25px;
  }
}

.banner-wrap .banner-content-2 h2 {
  font-size: 48px;
  margin: 0;
}

.banner-wrap .banner-content-2 h2.font-size-dec {
  font-size: 40px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-2 h2.font-size-dec {
    font-size: 33px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h2.font-size-dec {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h2.font-size-dec {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h2.font-size-dec {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2.font-size-dec {
    font-size: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2.font-size-dec {
    font-size: 22px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-2 h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 10px;
  line-height: 1;
  margin: 13px 0 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 20px;
    margin: 8px 0 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 17px;
    margin: 7px 0 8px;
  }
}

.banner-wrap .banner-content-2 h4 {
  font-size: 24px;
  font-weight: bold;
  color: #e4573d;
  margin: 0 0 26px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h4 {
    margin: 5px 0 5px;
    font-size: 20px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  left: 30px;
  top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 {
    top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 {
    left: 20px;
    top: 15px;
  }
}

.banner-wrap .banner-content-3 span {
  font-size: 15px;
  text-transform: uppercase;
}

.banner-wrap .banner-content-3 h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  margin: 3px 0 6px;
  width: 70%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 22px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 22px;
    width: 100%;
  }
}

.banner-wrap .banner-content-3 h4 {
  font-size: 24px;
  font-weight: bold;
  color: #4e97fd;
  margin: 0 0 30px;
}

.banner-wrap .banner-content-3 h4.red {
  color: #e4573d;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h4 {
    margin: 0 0 12px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h4 {
    margin: 0 0 20px;
    font-size: 22px;
  }
}

.banner-wrap .banner-badge {
  width: 74px;
  height: 74px;
  line-height: 74px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd55a;
}

.banner-wrap .banner-badge h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.banner-wrap .banner-badge h3 span {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 3px;
}

.banner-wrap .banner-badge.banner-badge-position1 {
  position: absolute;
  bottom: 12%;
  left: 45%;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-badge.banner-badge-position1 {
    left: 65%;
  }
}

.banner-wrap .banner-badge.banner-badge-position2 {
  position: absolute;
  top: 14%;
  right: 8%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-badge.banner-badge-position2 {
    top: 40%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-badge.banner-badge-position2 {
    top: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-badge.banner-badge-position2 {
    top: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-badge.banner-badge-position2 {
    top: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-badge.banner-badge-position2 {
    top: 47%;
  }
}

.banner-wrap .banner-badge-2 {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd55a;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-badge-2 {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.banner-wrap .banner-badge-2 h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-badge-2 h3 {
    font-size: 16px;
  }
}

.banner-wrap .banner-badge-2 h3 span {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: 30px;
  margin: 0 0 3px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-badge-2 h3 span {
    font-size: 18px;
  }
}

.banner-wrap .banner-badge-2.banner-badge-2-modify-1 {
  width: 74px;
  height: 74px;
  line-height: 74px;
}

.banner-wrap .banner-badge-2.banner-badge-2-modify-1 h3 {
  font-size: 16px;
}

.banner-wrap .banner-badge-2.banner-badge-2-modify-1 h3 span {
  font-size: 20px;
}

.banner-wrap .banner-badge-2.banner-badge-2-position1 {
  position: absolute;
  bottom: 10%;
  left: 45%;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-badge-2.banner-badge-2-position1 {
    bottom: 8%;
    left: 70%;
  }
}

.banner-wrap:hover .banner-img-zoom a img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.banner-wrap:hover .btn-style-1 a {
  background-color: #e4573d;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area-mt {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area-mt {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area-mt {
    margin-top: 10px;
  }
  .banner-area.pb-45 {
    padding-bottom: 30px;
  }
  .banner-area.pb-40 {
    padding-bottom: 25px;
  }
}

/*-------- 6. Section title style ---------*/
.section-title-timer-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  transform: translateY(-18px);
  padding: 0px 30px 0 30px;
  background-color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-timer-wrap {
    padding: 0px 25px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-timer-wrap {
    display: block;
    transform: translateY(-12px);
    padding: 0px 10px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-timer-wrap {
    display: inline-flex;
  }
}

.section-title-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .section-title-btn-wrap.mb-35 {
    margin-bottom: 25px;
  }
  .section-title-btn-wrap.st-btn-wrap-xs-center {
    justify-content: center;
  }
}

.section-title-1 h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1 h2.section-title-hm2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1 h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-1 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1.mb-30 {
    margin-bottom: 20px;
  }
  .section-title-1.mb-40 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-1.section-title-hm2 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-1.section-title-hm2 {
    text-align: center;
  }
  .section-title-1.section-title-hm2 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

.section-title-2 h2 {
  font-size: 34px;
  font-weight: 500;
  line-height: 1.42em;
  margin: 0 auto;
  width: 70%;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-2 h2 {
    font-size: 30px;
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 h2 {
    font-size: 26px;
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 20px;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-2 h2 {
    font-size: 22px;
  }
}

.section-title-2 p {
  font-size: 15px;
  color: #444;
  line-height: 1.74;
  width: 55%;
  margin: 15px auto 0;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title-2 p {
    width: 58%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2 p {
    width: 77%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2 p {
    width: 100%;
    margin: 10px auto 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-2 p {
    width: 84%;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-35 {
    margin-bottom: 25px;
  }
  .section-title-2.mb-45 {
    margin-bottom: 35px;
  }
}

/*-------- 25. account style ---------*/
.timer-style-1 {
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 6px 24px 5px;
  margin-left: 20px;
  background-color: #e4573d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1 {
    margin-left: 10px;
    padding: 6px 20px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1 {
    margin-left: 0px;
    margin-top: 10px;
    padding: 6px 15px 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .timer-style-1 {
    margin-left: 10px;
    margin-top: 0px;
  }
}

.timer-style-1 span {
  color: #ffffff;
  font-size: 20px;
  line-height: 1;
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1 span {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1 span {
    margin-right: 10px;
    font-size: 15px;
  }
}

.timer-style-1 .syotimer__body {
  display: flex;
}

.timer-style-1 .syotimer__body .syotimer-cell {
  margin-right: 23px;
  position: relative;
}

.timer-style-1 .syotimer__body .syotimer-cell::before {
  position: absolute;
  content: "";
  right: -12px;
  top: 28%;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #ffffff;
}

.timer-style-1 .syotimer__body .syotimer-cell::after {
  position: absolute;
  content: "";
  right: -12px;
  bottom: 22%;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #ffffff;
}

.timer-style-1 .syotimer__body .syotimer-cell:last-child {
  margin-right: 0;
}

.timer-style-1 .syotimer__body .syotimer-cell:last-child::before {
  display: none;
}

.timer-style-1 .syotimer__body .syotimer-cell:last-child::after {
  display: none;
}

.timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value {
  color: #ffffff;
  font-weight: bold;
  font-size: 23px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value {
    font-size: 16px;
  }
}

.timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__unit {
  display: none;
}

.product-area-border {
  border: 2px solid #4e97fd;
  border-radius: 5px;
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .product-area-border {
    padding: 0 20px;
  }
}

.single-product-wrap {
  position: relative;
}

.single-product-wrap .product-img-action-wrap {
  position: relative;
}

.single-product-wrap .product-img-action-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.single-product-wrap .product-img-action-wrap .product-img a img {
  width: 100%;
}

.single-product-wrap .product-img-action-wrap .product-img a img.hover-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s opacity, 0.25s visibility, transform 1.5s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.single-product-wrap .product-img-action-wrap .product-img-zoom a img {
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.single-product-wrap .product-img-action-wrap .product-action-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  z-index: 9;
}

.single-product-wrap .product-img-action-wrap .product-action-1 button {
  border: none;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  background-color: #333;
  text-align: center;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.09);
  margin-right: 7px;
  position: relative;
}

.single-product-wrap .product-img-action-wrap .product-action-1 button:after {
  bottom: 100%;
  left: 50%;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #4e97fd;
  content: attr(aria-label);
  line-height: 1.3;
  transition-delay: .1s;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(0px);
}

.single-product-wrap .product-img-action-wrap .product-action-1 button:before {
  content: '';
  position: absolute;
  left: calc(50% - 7px);
  bottom: 100%;
  transition-delay: .1s;
  border: 7px solid transparent;
  border-top-color: #4e97fd;
  z-index: 9;
  margin-bottom: -13px;
  transition-delay: .1s;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
}

.single-product-wrap .product-img-action-wrap .product-action-1 button:last-child {
  margin-right: 0;
}

.single-product-wrap .product-img-action-wrap .product-action-1 button i {
  font-size: 15px;
  color: #ffffff;
}

.single-product-wrap .product-img-action-wrap .product-action-1 button:hover {
  background-color: #4e97fd;
}

.single-product-wrap .product-img-action-wrap .product-action-1 button:hover:after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-8px);
}

.single-product-wrap .product-img-action-wrap .product-action-1 button:hover:before {
  opacity: 1;
  visibility: visible;
  transform: translateY(-8px);
}

.single-product-wrap .product-badges {
  display: flex;
}

.single-product-wrap .product-badges.product-badges-mrg {
  margin: 0 0 10px;
}

.single-product-wrap .product-badges.product-badges-position {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.single-product-wrap .product-badges span {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  border-radius: 2px;
  color: #ffffff;
  margin-right: 10px;
  padding: 5px 8px 6px;
}

.single-product-wrap .product-badges span:last-child {
  margin-right: 0px;
}

.single-product-wrap .product-badges span.yellow {
  background-color: #f6b500;
}

.single-product-wrap .product-badges span.red {
  background-color: #e4573d;
}

.single-product-wrap .product-badges span.red-2 {
  background-color: #d31129;
}

.single-product-wrap .product-content-wrap .product-category a {
  color: #555;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.single-product-wrap .product-content-wrap .product-category a:hover {
  color: #4e97fd;
}

.single-product-wrap .product-content-wrap h2 {
  font-size: 18px;
  margin: 8px 0 7px;
}

.single-product-wrap .product-content-wrap h2 a {
  color: #333;
}

.single-product-wrap .product-content-wrap h2 a:hover {
  color: #4e97fd;
}

.single-product-wrap .product-content-wrap .product-price span {
  font-size: 18px;
  font-weight: bold;
  color: #4e97fd;
}

.single-product-wrap .product-content-wrap .product-price span.new-price {
  color: #e4573d;
}

.single-product-wrap .product-content-wrap .product-price span.old-price {
  font-size: 14px;
  font-weight: 400;
  color: #444;
  margin: 0 0 0 7px;
  text-decoration: line-through;
}

.single-product-wrap .product-stock .status-bar {
  background-color: #ededed;
  margin: 0px 0 10px;
  border-radius: 5px;
}

.single-product-wrap .product-stock .status-bar .sold-bar {
  background-image: linear-gradient(235deg, #4e97fd 0%, #77ccfd 100%);
  border-radius: 4px;
  height: 8px;
}

.single-product-wrap .product-stock .status-bar .sold-bar.sold-bar-width-33 {
  width: 33.333333333333%;
}

.single-product-wrap .product-stock .status-bar .sold-bar.sold-bar-width-10 {
  width: 10%;
}

.single-product-wrap .product-stock .status-bar .sold-bar.sold-bar-width-40 {
  width: 40%;
}

.single-product-wrap .product-stock .status-bar .sold-bar.sold-bar-width-6 {
  width: 6.6666666666667%;
}

.single-product-wrap .product-stock .status-bar .sold-bar.sold-bar-width-42 {
  width: 42.857142857143%;
}

.single-product-wrap .product-stock .product-stock-status {
  display: flex;
  flex-wrap: wrap;
}

.single-product-wrap .product-stock .product-stock-status .sold {
  flex-grow: 1;
  margin-right: 20px;
}

.single-product-wrap .product-stock .product-stock-status .available {
  flex-shrink: 0;
}

.single-product-wrap .product-stock .product-stock-status .stock-status-same-style span {
  font-size: 15px;
}

.single-product-wrap .product-stock .product-stock-status .stock-status-same-style span.label {
  color: #444;
}

.single-product-wrap .product-stock .product-stock-status .stock-status-same-style span.value {
  font-weight: 700;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.single-product-wrap:hover .product-img-action-wrap .product-img a img.hover-img {
  opacity: 1;
  visibility: visible;
}

.single-product-wrap:hover .product-img-action-wrap .product-action-1 {
  opacity: 1;
  visibility: visible;
}

.single-product-wrap:hover .product-img-zoom a img {
  transform: scale3d(1.1, 1.1, 1.1) translateZ(0);
}

.product-hm1-mrg {
  margin-top: 30px;
  margin-bottom: 48px;
}

@media only screen and (max-width: 767px) {
  .product-hm1-mrg {
    margin-bottom: 30px;
  }
}

.product-slider-active-1,
.categories-slider-1 {
  margin-left: -10px;
  margin-right: -10px;
}

.product-slider-active-1 .product-plr-1,
.categories-slider-1 .product-plr-1 {
  padding: 0 10px;
}

.nav-style-2 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -3.5%;
  text-align: center;
  cursor: pointer;
  transition: all .6s ease 0s;
  z-index: 99;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  font-size: 28px;
  background: #ffffff;
  color: #696969;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.09);
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-2 > span {
    left: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span {
    width: 35px;
    height: 35px;
  }
}

.nav-style-2 > span i {
  line-height: 52px;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .nav-style-2 > span i {
    line-height: 35px;
  }
}

.nav-style-2 > span.pro-icon-1-next {
  left: auto;
  right: -3.5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-2 > span.pro-icon-1-next {
    right: 0%;
  }
}

.nav-style-2 > span:hover {
  color: #4e97fd;
}

.nav-style-2.nav-style-2-modify-2 > span {
  left: -3.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-2.nav-style-2-modify-2 > span {
    left: 0%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-2.nav-style-2-modify-2 > span {
    left: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-2.nav-style-2-modify-2 > span {
    left: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-2.nav-style-2-modify-2 > span {
    left: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2.nav-style-2-modify-2 > span {
    left: 0%;
  }
}

.nav-style-2.nav-style-2-modify-2 > span.pro-icon-1-next {
  left: auto;
  right: -3.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-2.nav-style-2-modify-2 > span.pro-icon-1-next {
    right: 0%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-2.nav-style-2-modify-2 > span.pro-icon-1-next {
    right: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-2.nav-style-2-modify-2 > span.pro-icon-1-next {
    right: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-2.nav-style-2-modify-2 > span.pro-icon-1-next {
    right: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-2.nav-style-2-modify-2 > span.pro-icon-1-next {
    right: 0%;
  }
}

.nav-style-2.nav-style-2-modify-1 > span {
  left: 2%;
}

.nav-style-2.nav-style-2-modify-1 > span.pro-icon-1-next {
  left: auto;
  right: 2%;
}

.nav-style-2.nav-style-2-modify-3 > span {
  left: 0%;
  width: 48px;
  height: 48px;
  color: #333;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .nav-style-2.nav-style-2-modify-3 > span {
    width: 38px;
    height: 38px;
  }
}

.nav-style-2.nav-style-2-modify-3 > span:hover {
  color: #ffffff;
  border: 1px solid #333;
  background-color: #333;
}

.nav-style-2.nav-style-2-modify-3 > span i {
  line-height: 46px;
}

@media only screen and (max-width: 767px) {
  .nav-style-2.nav-style-2-modify-3 > span i {
    line-height: 36px;
  }
}

.nav-style-2.nav-style-2-modify-3 > span.pro-icon-1-next {
  left: auto;
  right: 0%;
}

.nav-style-2:hover > span {
  opacity: 1;
  visibility: visible;
}

.categories-wrap {
  background: #f8f8f8;
  padding: 25px 30px 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .categories-wrap {
    padding: 25px 15px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-wrap {
    padding: 25px 10px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .categories-wrap {
    padding: 25px 20px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .categories-wrap {
    padding: 25px 20px 30px;
  }
}

.categories-wrap .categories-img a {
  display: block;
}

.categories-wrap .categories-img a img {
  width: 100%;
}

.categories-wrap .categories-img-zoom {
  overflow: hidden;
}

.categories-wrap .categories-img-zoom a img {
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.categories-wrap .categories-content {
  margin: 13px 0 0;
}

.categories-wrap .categories-content h3 {
  font-size: 18px;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .categories-wrap .categories-content h3 {
    font-size: 17px;
  }
}

.categories-wrap .categories-content h3 a {
  color: #333;
}

.categories-wrap .categories-content h3 a:hover {
  color: #4e97fd;
}

.categories-wrap:hover .categories-img-zoom a img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.btn-style-2 a {
  font-size: 15px;
  font-weight: 700;
  color: #333;
}

.btn-style-2 a i {
  font-size: 16px;
  margin-left: 14px;
  position: relative;
  top: 2px;
  color: #4e97fd;
}

.btn-style-2 a:hover {
  color: #4e97fd;
}

.slidebar-product-wrap {
  padding: 38px 30px 53px;
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  margin-right: 35px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slidebar-product-wrap {
    padding: 38px 20px 53px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slidebar-product-wrap {
    padding: 38px 20px 53px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slidebar-product-wrap {
    padding: 38px 20px 53px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slidebar-product-wrap {
    margin-right: 0px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slidebar-product-wrap {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

.slidebar-product-wrap.slidebar-product-bg-1 {
  background-color: #f1f1f1;
  background-image: url(../../assets/images/bg/bg-1.jpg);
}

.slidebar-product-wrap.slidebar-product-bg-2 {
  background-color: #f6f2ed;
}

.slidebar-product-wrap.slidebar-product-bg-3 {
  background-image: linear-gradient(230deg, #f6fafe 0%, #edeff6 82%);
}

.slidebar-product-wrap .slidebar-product-content h3 {
  font-size: 18px;
  margin: 0 0 10px;
}

.slidebar-product-wrap .slidebar-product-content h2 {
  font-size: 24px;
  line-height: 1.5em;
  margin: 0;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slidebar-product-wrap .slidebar-product-content h2 {
    font-size: 22px;
    line-height: 1.3em;
  }
}

.slidebar-product-wrap .slidebar-product-img {
  margin: 6px 0 55px;
}

.slidebar-product-wrap .slidebar-product-img > a {
  display: block;
}

.slidebar-product-wrap .slidebar-product-img > a img {
  max-width: 100%;
}

.slidebar-product-wrap .slidebar-product-img.slidebar-product-mrg-1 > a {
  margin: 38px 0 27px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slidebar-product-wrap .slidebar-product-details {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .slidebar-product-wrap .slidebar-product-details {
    display: flex;
    justify-content: center;
  }
}

.slidebar-product-wrap .slidebar-product-details ul li {
  margin-bottom: 12px;
}

.slidebar-product-wrap .slidebar-product-details ul li:last-child {
  margin-bottom: 0;
}

.slidebar-product-wrap .slidebar-product-details ul li a {
  font-size: 16px;
  color: #444;
  display: flex;
}

.slidebar-product-wrap .slidebar-product-details ul li a i {
  font-size: 14px;
  margin-right: 9px;
  position: relative;
  top: 6px;
}

.slidebar-product-wrap .slidebar-product-details ul li a:hover {
  color: #4e97fd;
}

.sidebar-product-wrap .single-sidebar-product {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.sidebar-product-wrap .single-sidebar-product:last-child {
  margin-bottom: 0;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-img-3 {
  flex: 0 0 80px;
  max-width: 80px;
  margin-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-product-wrap .single-sidebar-product .slidebar-product-img-3 {
    flex: 0 0 70px;
    max-width: 70px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-product-wrap .single-sidebar-product .slidebar-product-img-3 {
    flex: 0 0 70px;
    max-width: 70px;
    margin-right: 10px;
  }
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-img-3 a {
  display: block;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-img-3 a img {
  width: 100%;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-content-3 h4 {
  font-size: 15px;
  margin: 0 0 7px;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-content-3 h4 a {
  color: #333;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-content-3 h4 a:hover {
  color: #4e97fd;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-content-3 .slidebar-pro-price span {
  font-size: 15px;
  font-weight: 700;
  color: #4e97fd;
  display: inline-block;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-content-3 .slidebar-pro-price span.old-price {
  text-decoration: line-through;
  color: #444;
  margin: 0 0 0 7px;
  font-weight: 400;
}

.sidebar-product-wrap .single-sidebar-product .slidebar-product-content-3 .slidebar-pro-price span.new-price {
  color: #e4573d;
}

.slick-current .single-product-wrap .product-img-action-wrap .product-action-1 button:first-child:after {
  left: 0;
  transform: translateX(0%) translateY(0px);
}

.slick-current .single-product-wrap .product-img-action-wrap .product-action-1 button:hover:first-child:after {
  transform: translateX(0%) translateY(-8px);
}

@media only screen and (max-width: 767px) {
  .product-area.pt-90 {
    padding-top: 70px;
  }
  .product-area.pb-20 {
    padding-bottom: 25px;
  }
  .shop-area.pt-75 {
    padding-top: 53px;
  }
}

/*-------- 7. Brand logo style ---------*/
.single-brand-logo {
  display: flex;
  justify-content: center;
}

.single-brand-logo a {
  display: block;
}

.single-brand-logo a img {
  max-width: 100%;
}

.single-brand-logo:hover a img {
  filter: grayscale(100%);
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pb-35 {
    padding-bottom: 20px;
  }
  .brand-logo-area.pb-70 {
    padding-bottom: 20px;
  }
}

/*-------- 8. Testimonial style ---------*/
.testimonial-area {
  position: relative;
}

.testimonial-area .pagingInfo {
  position: absolute;
  bottom: 13%;
  left: 10%;
  font-size: 18px;
  font-weight: 700;
  color: #333;
  z-index: 5;
}

.testimonial-active-1,
.testimonial-active-3 {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial-active-1 .testimonial-plr-1,
.testimonial-active-3 .testimonial-plr-1 {
  padding: 0 15px;
}

.single-testimonial {
  position: relative;
  padding: 56px 40px 55px;
  background-image: linear-gradient(228deg, #f6fafe 0%, #edeff6 82%);
  border-radius: 5px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-testimonial {
    padding: 56px 32px 55px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial {
    padding: 56px 30px 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial {
    padding: 56px 20px 55px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial {
    padding: 46px 30px 45px;
  }
}

.single-testimonial::before {
  content: '';
  position: absolute;
  top: 44px;
  right: 38px;
  width: 34px;
  height: 28px;
  background: url(../images/icon-img/testimonial-icon.png) no-repeat center;
  background-size: 100% auto;
}

.single-testimonial h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.single-testimonial p {
  font-size: 18px;
  line-height: 30px;
  color: #333;
  margin: 22px 0 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial p {
    margin: 22px 0 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial p {
    margin: 22px 0 23px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial p {
    margin: 22px 0 23px;
  }
}

.single-testimonial .client-info h5 {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px;
}

.single-testimonial .client-info span {
  font-size: 15px;
  color: #444;
}

.single-testimonial-2 {
  position: relative;
  padding: 77px 80px 111px;
  background-image: linear-gradient(235deg, #e6eef5 0%, #edeff6 100%);
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-testimonial-2 {
    padding: 66px 65px 111px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2 {
    padding: 66px 50px 111px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 {
    padding: 66px 50px 111px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 {
    padding: 50px 20px 80px;
  }
}

.single-testimonial-2::before {
  content: '';
  position: absolute;
  top: 58px;
  right: 49px;
  width: 60px;
  height: 48px;
  background: url(../images/icon-img/testimonial-icon.png) no-repeat center;
  background-size: 100% auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2::before {
    top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2::before {
    top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2::before {
    top: 20px;
    right: 20px;
    width: 35px;
    height: 35px;
  }
}

.single-testimonial-2 h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.single-testimonial-2 p {
  font-size: 24px;
  line-height: 1.5;
  color: #333;
  margin: 15px 0 41px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2 p {
    font-size: 20px;
    margin: 15px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 p {
    font-size: 20px;
    margin: 15px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 p {
    font-size: 18px;
    margin: 15px 0 20px;
  }
}

.single-testimonial-2 .client-info-2 h5 {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px;
}

.single-testimonial-2 .client-info-2 span {
  font-size: 15px;
  color: #444;
}

.nav-style-3 > span {
  position: absolute;
  bottom: 10%;
  right: 8.5%;
  cursor: pointer;
  color: #111;
  width: 52px;
  height: 52px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
  font-size: 28px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
}

.nav-style-3 > span i {
  line-height: 52px;
  font-weight: 300;
}

@media only screen and (max-width: 767px) {
  .nav-style-3 > span i {
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-3 > span {
    width: 35px;
    height: 35px;
    font-size: 16px;
    bottom: 12%;
  }
}

.nav-style-3 > span:hover {
  color: #4e97fd;
  background: white;
}

.nav-style-3 > span.testimonial-icon-2-prev {
  margin-right: 62px;
}

@media only screen and (max-width: 767px) {
  .nav-style-3 > span.testimonial-icon-2-prev {
    margin-right: 45px;
  }
}

/*-------- 9. Contact us style ---------*/
.single-contact-wrap {
  padding: 35px 30px 40px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-contact-wrap {
    padding: 35px 20px 40px;
  }
}

.single-contact-wrap h4 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 12px;
}

.single-contact-wrap p {
  font-size: 20px;
  color: #444;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-contact-wrap p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-contact-wrap p {
    font-size: 18px;
  }
}

.single-contact-wrap:hover {
  background-color: #ffffff;
}

.contact-map #map {
  height: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-map #map {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-map #map {
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-map #map {
    height: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .contact-map #map {
    height: 350px;
  }
}

.contact-us-bg {
  background-color: #f7f9fb;
  background-image: url(../../assets/images/bg/bg-3.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
}

.contact-from-area.contact-from-area-bg {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.15);
  padding: 63px 65px 66px;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-from-area.contact-from-area-bg {
    padding: 63px 35px 66px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-from-area.contact-from-area-bg {
    padding: 53px 20px 56px;
  }
}

.contact-from-area h3 {
  font-size: 34px;
  font-weight: 500;
  margin: 0 0 50px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .contact-from-area h3 {
    font-size: 25px;
    margin: 0 0 30px;
  }
}

.contact-from-area .contact-form-style .textarea-style textarea {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 48px;
  color: #666;
  padding: 12px 20px 3px;
  border-radius: 5px;
  font-size: 15px;
  height: 150px;
}

.contact-from-area .contact-form-style .textarea-style textarea::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

.contact-from-area .contact-form-style .textarea-style textarea::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.contact-from-area .contact-form-style .textarea-style textarea:focus {
  background-color: #ffffff;
  border: 1px solid #4e97fd;
}

.contact-from-area .contact-form-style button {
  font-size: 15px;
  font-weight: 700;
  padding: 12px 41px;
  color: #ffffff;
  border: none;
  background-color: #4e97fd;
  width: 100%;
  border-radius: 26px;
}

.contact-from-area .contact-form-style button.submit-auto-width {
  width: auto;
  display: inline-block;
}

.contact-from-area .contact-form-style button:hover {
  background-color: #e4573d;
}

.contact-from-area p {
  font-size: 15px;
  text-align: center;
  margin: 20px 0 0;
}

.contact-info-wrap {
  margin: 0 0 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-wrap {
    margin: 50px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap {
    margin: 50px 0 0 0px;
  }
}

.contact-info-wrap .single-contact-info2-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 55px;
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .single-contact-info2-wrap {
    margin: 0 0 35px;
  }
}

.contact-info-wrap .single-contact-info2-wrap:last-child {
  margin: 0 0 0px;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-icon {
  margin: 0 25px 0 0;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-icon i {
  font-size: 40px;
  color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-icon i {
    font-size: 35px;
  }
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content p {
  font-size: 16px;
  line-height: 1.625em;
  color: #444;
  margin: 0 0 0px;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h2 {
  font-size: 48px;
  font-weight: 700;
  margin: 10px 0 0px;
  line-height: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h2 {
    font-size: 25px;
    line-height: 25px;
  }
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h2 a {
  color: #333;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h2 a:hover {
  color: #4e97fd;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h3 {
  font-size: 34px;
  margin: 18px 0 0px;
  line-height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h3 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h3 {
    font-size: 25px;
    margin: 10px 0 0px;
    line-height: 25px;
  }
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h3 a {
  color: #333;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h3 a:hover {
  color: #4e97fd;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h4 {
  font-size: 20px;
  margin: 8px 0 0px;
  line-height: 30px;
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h4 {
    font-size: 18px;
    line-height: 28px;
  }
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h4 a {
  color: #333;
}

.contact-info-wrap .single-contact-info2-wrap .single-contact-info2-content h4 a:hover {
  color: #4e97fd;
}

.single-contact-info3-wrap {
  display: flex;
}

.single-contact-info3-wrap .single-contact-info3-icon {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .single-contact-info3-wrap .single-contact-info3-icon {
    margin-right: 12px;
  }
}

.single-contact-info3-wrap .single-contact-info3-icon i {
  font-size: 32px;
  color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .single-contact-info3-wrap .single-contact-info3-icon i {
    font-size: 28px;
  }
}

.single-contact-info3-wrap .single-contact-info3-content h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 23px;
}

@media only screen and (max-width: 767px) {
  .single-contact-info3-wrap .single-contact-info3-content h3 {
    margin: 0 0 10px;
  }
}

.single-contact-info3-wrap .single-contact-info3-content p {
  font-size: 15px;
  color: #444;
  margin: 0 0 3px;
}

.single-contact-info3-wrap .single-contact-info3-content p.width-1 {
  width: 90%;
}

@media only screen and (max-width: 767px) {
  .single-contact-info3-wrap .single-contact-info3-content p.width-1 {
    width: 100%;
  }
}

.single-contact-info3-wrap .single-contact-info3-content p span {
  font-weight: 700;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .mb-60 {
    margin-bottom: 30px;
  }
  .contact-us-area.pt-65 {
    padding-top: 55px;
  }
  .contact-info-wrap-2.mb-40 {
    margin-bottom: 25px;
  }
}

/*-------- 10. Footer style ---------*/
.footer-logo a {
  display: block;
}

.footer-logo.logo-width-1 a img {
  width: 166px;
}

@media only screen and (max-width: 767px) {
  .footer-logo.logo-width-1 a img {
    width: 150px;
  }
}

.footer-logo {
  margin: 0 0 36px;
}

@media only screen and (max-width: 767px) {
  .footer-logo {
    margin: 0 0 17px;
  }
}

.copyright p {
  line-height: 1.6em;
  color: #444;
  font-size: 15px;
}

.copyright p a {
  color: #444;
}

.copyright p a:hover {
  color: #4e97fd;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright.copyright-center {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .copyright.copyright-center {
    text-align: center;
  }
}

.footer-widget h3.footer-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget h3.footer-title {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget h3.footer-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title {
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget h3.footer-title {
    font-size: 18px;
  }
}

.footer-widget .footer-info-list ul li {
  display: block;
  margin: 0 0 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-info-list ul li {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .footer-info-list ul li {
    margin: 0 0 8px;
  }
}

.footer-widget .footer-info-list ul li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-info-list ul li a {
  font-size: 15px;
  color: #444;
  display: block;
}

.footer-widget .footer-info-list ul li a:hover {
  color: #4e97fd;
}

.footer-widget .app-visa-wrap p {
  color: #444;
  font-size: 15px;
  margin: 0;
}

.footer-widget .app-visa-wrap .app-google-img {
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .app-visa-wrap .app-google-img {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .app-visa-wrap .app-google-img {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .app-visa-wrap .app-google-img {
    margin: 15px 0 15px;
  }
}

.footer-widget .app-visa-wrap .app-google-img a {
  display: block;
  margin-right: 12px;
}

.footer-widget .app-visa-wrap .app-google-img a:last-child {
  margin-right: 0px;
}

.footer-widget .app-visa-wrap .app-google-img a img {
  width: 170px;
  border-radius: 3px;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .app-visa-wrap .app-google-img a img {
    width: 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .app-visa-wrap .app-google-img a img {
    width: 115px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .app-visa-wrap .app-google-img a img {
    width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .app-visa-wrap .app-google-img a img {
    width: 120px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget .app-visa-wrap .app-google-img a img {
    width: 105px;
  }
}

.footer-widget .app-visa-wrap .payment-img {
  margin: 16px 0 0;
}

.footer-widget .app-visa-wrap .payment-img a {
  display: block;
}

.footer-widget .app-visa-wrap .payment-img a img {
  max-width: 100%;
}

.footer-about-2 .footer-contact-info p {
  color: #444;
  font-size: 15px;
  margin: 0 0 8px;
}

.footer-about-2 .footer-contact-info p span {
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin: 4px 0 18px;
  color: #e4573d;
}

.footer-about-2 .footer-social-icon {
  margin: 24px 0 0;
}

.footer-about-2 .footer-social-icon a {
  font-size: 20px;
  margin-right: 22px;
}

.footer-about-2 .footer-social-icon a:last-child {
  margin-right: 0;
}

.footer-about-2 .footer-social-icon a.twitter {
  color: #26d0fe;
}

.footer-about-2 .footer-social-icon a.facebook {
  color: #567fa9;
}

.footer-about-2 .footer-social-icon a.skype {
  color: #13baf7;
}

.footer-about-2 .footer-social-icon a.youtube {
  color: #da0e2b;
}

.footer-about-2 .footer-social-icon a.instagram {
  color: #987545;
}

.subscribe-wrap p {
  font-size: 15px;
  color: #444;
  line-height: 1.6em;
  margin: 0 0 37px;
}

@media only screen and (max-width: 767px) {
  .subscribe-wrap p {
    margin: 0 0 17px;
  }
}

.subscribe-form form .mc-form {
  position: relative;
}

.subscribe-form form .mc-form input {
  color: #444;
  font-size: 15px;
  height: 48px;
  border: 1px solid #f8f8f8;
  border-radius: 26px;
  padding: 2px 160px 2px 20px;
  background-color: #fff;
  line-height: 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form form .mc-form input {
    padding: 2px 140px 2px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form input {
    padding: 2px 140px 2px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form form .mc-form input {
    padding: 2px 116px 2px 20px;
  }
}

.subscribe-form form .mc-form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.subscribe-form form .mc-form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.subscribe-form form .mc-form .mc-news {
  display: none;
}

.subscribe-form form .mc-form .clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form form .mc-form .clear input {
  width: auto;
  font-weight: 700;
  color: #ffffff;
  background-color: #4e97fd;
  padding: 0 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-form form .mc-form .clear input {
    padding: 0 26px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-form form .mc-form .clear input {
    padding: 0 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form form .mc-form .clear input {
    padding: 0 16px;
  }
}

.subscribe-form form .mc-form .clear input:hover {
  background-color: #e4573d;
}

.payment-img-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-img-wrap {
    justify-content: center;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .payment-img-wrap {
    justify-content: center;
    margin-bottom: 10px;
    display: block;
  }
}

.payment-img-wrap span {
  color: #444;
  font-size: 15px;
  margin: 0 30px 0 0;
}

@media only screen and (max-width: 767px) {
  .payment-img-wrap span {
    text-align: center;
    display: block;
    margin: 0 0px 5px 0;
  }
}

.payment-img-wrap .payment-img-2 {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .payment-img-wrap .payment-img-2 {
    text-align: center;
    display: block;
  }
}

.payment-img-wrap .payment-img-2 img {
  max-width: 100%;
}

.footer-bottom {
  padding: 30px 0;
}

@media only screen and (max-width: 767px) {
  .mrg-top-xs {
    margin-top: 7px;
  }
  .footer-area.pb-35 {
    padding-bottom: 20px;
  }
  .footer-top.pb-30 {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .mrg-top-xs {
    margin-top: 0px;
  }
}

/*-------- 11. Blog style ---------*/
.blog-wrap {
  display: flex;
  flex-wrap: wrap;
}

.blog-wrap .blog-img {
  position: relative;
  flex: 0 0 45%;
  padding-right: 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-img {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img {
    flex: 0 0 100%;
    padding-right: 0px;
  }
}

.blog-wrap .blog-img a {
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

.blog-wrap .blog-img a img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.blog-wrap .blog-img .blog-tag {
  position: absolute;
  left: 10px;
  bottom: 10px;
  line-height: 1;
}

.blog-wrap .blog-img .blog-tag a {
  background-color: #4e97fd;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 1;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 5px;
  padding: 6px 11px 6px;
}

.blog-wrap .blog-content {
  flex: 0 0 55%;
  margin: 15px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content {
    margin: 0px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content {
    flex: 0 0 100%;
  }
}

.blog-wrap .blog-content .blog-meta span {
  font-size: 14px;
  color: #444;
}

.blog-wrap .blog-content .blog-meta span i {
  margin-right: 6px;
}

.blog-wrap .blog-content h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  margin: 9px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap .blog-content h3 {
    font-size: 19px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3 {
    line-height: 1.3;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    line-height: 1.3;
    margin: 5px 0 0;
  }
}

.blog-wrap .blog-content h3 a {
  color: #333;
}

.blog-wrap .blog-content h3 a:hover {
  color: #4e97fd;
}

.blog-wrap:hover .blog-img a img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.blog-wrap-2 {
  border-radius: 5px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.blog-wrap-2 .blog-img-2 {
  display: block;
  overflow: hidden;
  position: relative;
}

.blog-wrap-2 .blog-img-2 a {
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

.blog-wrap-2 .blog-img-2 a img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.blog-wrap-2 .blog-img-2 .blog-tag-2 {
  position: absolute;
  left: 10px;
  top: 10px;
}

.blog-wrap-2 .blog-img-2 .blog-tag-2 a {
  background-color: #4e97fd;
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
  font-weight: 500;
  letter-spacing: 1;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 5px;
  padding: 6px 11px 6px;
}

.blog-wrap-2 .blog-content-2 {
  padding: 26px 30px 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 26px 25px 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 26px 25px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 26px 20px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 26px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 26px 20px 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 {
    padding: 26px 15px 30px;
  }
}

.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li {
  font-size: 14px;
  color: #444;
  display: inline-block;
  margin-right: 20px;
}

.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li i {
  margin-right: 6px;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li i {
    margin-right: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 .blog-meta-2 ul li {
    margin-right: 8px;
  }
}

.blog-wrap-2 .blog-content-2 .blog-meta-2 ul li:last-child {
  margin-right: 0;
}

.blog-wrap-2 .blog-content-2 h3 {
  font-size: 20px;
  margin: 13px 0 23px;
  line-height: 1.6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 18px;
    margin: 10px 0 15px;
    line-height: 1.5;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 18px;
    margin: 10px 0 15px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-2 .blog-content-2 h3 {
    font-size: 18px;
    margin: 10px 0 15px;
    line-height: 1.5;
  }
}

.blog-wrap-2 .blog-content-2 h3 a {
  color: #333;
}

.blog-wrap-2 .blog-content-2 h3 a:hover {
  color: #4e97fd;
}

.blog-wrap-2 .blog-content-2 .blog-btn a {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #333;
}

.blog-wrap-2 .blog-content-2 .blog-btn a i {
  margin-left: 14px;
  color: #4e97fd;
  font-size: 15px;
  position: relative;
  top: 2px;
}

.blog-wrap-2 .blog-content-2 .blog-btn a:hover {
  color: #4e97fd;
}

.blog-wrap-2:hover .blog-img-2 a img {
  transform: scale3d(1.1, 1.1, 1.1);
}

/*-------- 12. Others style ---------*/
.tm-demo-options-wrapper {
  position: fixed;
  top: 0;
  left: 100%;
  background: #fff;
  height: 100%;
  width: 350px;
  max-width: calc(100% - 45px);
  z-index: 999;
  transition: all .5s;
}

@media only screen and (max-width: 767px) {
  .tm-demo-options-wrapper {
    width: 300px;
  }
}

.tm-demo-options-wrapper .tm-demo-options-toolbar {
  position: absolute;
  top: 200px;
  right: 100%;
  display: block;
  width: 40px;
  line-height: 60px;
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #fff;
  text-align: center;
  box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}

.tm-demo-options-wrapper .tm-demo-options-toolbar a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #111;
  position: relative;
  z-index: 99;
}

.tm-demo-options-wrapper .tm-demo-options-toolbar a i {
  font-size: 16px;
}

.tm-demo-options-wrapper.demo-open {
  transform: translateX(-100%) translateX(15px);
  box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
}

.tm-demo-options-wrapper .tm-demo-panel {
  padding: 35px 30px;
  height: 100%;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .tm-demo-options-wrapper .tm-demo-panel {
    padding: 35px 35px 35px 20px;
  }
}

.tm-demo-options-wrapper .tm-demo-panel .tm-demo-panel-header {
  margin-bottom: 40px;
}

.tm-demo-options-wrapper .tm-demo-panel .tm-demo-panel-header h3 {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1.38px;
  line-height: 1.4;
}

.tm-demo-options-wrapper .tm-demo-panel .tm-demo-panel-header p {
  color: #444;
  line-height: 1.74;
  margin: 14px 0 20px;
}

.tm-demo-options-wrapper .tm-demo-panel .tm-demo-panel-header .tm-demo-btn a {
  display: inline-block;
  font-size: 15px;
  color: #ffffff;
  font-weight: 700;
  background-color: #4e97fd;
  border-radius: 26px;
  padding: 10px 28px;
}

.tm-demo-options-wrapper .tm-demo-panel .tm-demo-panel-header .tm-demo-btn a i {
  font-size: 16px;
  margin-right: 14px;
}

.tm-demo-options-wrapper .tm-demo-panel .tm-demo-panel-header .tm-demo-btn a:hover {
  background-color: #e4573d;
}

.tm-demo-options-wrapper .tm-demo-panel .quick-option-list a {
  display: inline-block;
  margin-bottom: 20px;
}

.tm-demo-options-wrapper .tm-demo-panel .quick-option-list a img {
  width: 100%;
  transition: all .3s;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.tm-demo-options-wrapper .tm-demo-panel .quick-option-list a img:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.tooltip-style-2 > a {
  position: relative;
}

.tooltip-style-2 > a:after {
  bottom: 50%;
  right: 100%;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 10px;
  color: #ffffff;
  margin-bottom: -15px;
  background-color: #000;
  content: attr(aria-label);
  line-height: 1.3;
  transition-delay: .1s;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.tooltip-style-2 > a:before {
  content: '';
  position: absolute;
  right: 100%;
  bottom: 50%;
  transition-delay: .1s;
  border: 7px solid transparent;
  border-left-color: #000;
  z-index: 9;
  margin-right: -13px;
  margin-bottom: -7px;
  transition-delay: .1s;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
}

.tooltip-style-2 > a:hover:after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-8px);
}

.tooltip-style-2 > a:hover:before {
  opacity: 1;
  visibility: visible;
  transform: translateX(-8px);
}

.medizin-common-style-wrap .medizin-common-img-style img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-img-style.mb-35 {
    margin-bottom: 15px;
  }
}

.medizin-common-style-wrap .medizin-common-paragraph-style {
  margin: 0 0 40px;
}

.medizin-common-style-wrap .medizin-common-paragraph-style.paragraph-mrg-dec {
  margin: 0 0 18px;
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style {
    margin: 0 0 25px;
  }
}

.medizin-common-style-wrap .medizin-common-paragraph-style h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style h2 {
    font-size: 25px;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style h2 {
    font-size: 25px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style h2 {
    font-size: 21px;
    margin: 0 0 10px;
  }
}

.medizin-common-style-wrap .medizin-common-paragraph-style h4 {
  font-weight: 700;
  color: #444;
  font-size: 15px;
  margin: 20px 0 0;
}

.medizin-common-style-wrap .medizin-common-paragraph-style h3 {
  font-weight: 700;
  color: #333;
  font-size: 26px;
  margin: 0px 0 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style h3 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style h3 {
    font-size: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style h3 {
    font-size: 20px;
  }
}

.medizin-common-style-wrap .medizin-common-paragraph-style p {
  font-size: 15px;
  color: #444;
  line-height: 1.74;
  margin: 0;
}

.medizin-common-style-wrap .medizin-common-paragraph-style p a {
  color: #444;
  font-weight: 700;
}

.medizin-common-style-wrap .medizin-common-paragraph-style p.paragraph-mrg-top {
  margin-top: 18px;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list ul {
  padding: 0 0 0 15px;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list ul li {
  margin: 0 0 11px;
  font-size: 15px;
  color: #444;
  list-style: disc;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list ul li:last-child {
  margin: 0 0 0px;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list.medizin-common-list-mrg-1 {
  margin: 20px 0 0;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2 ul {
  padding: 0 0 0 30px;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2 ul li {
  margin: 0 0 14px;
  font-size: 15px;
  color: #444;
  line-height: 1.74;
  list-style: decimal;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2 ul li:last-child {
  margin: 0 0 0px;
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2.medizin-common-list-mrg-2 {
  margin: 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2.medizin-common-list-mrg-2 {
    margin: 15px 0 0;
  }
}

.medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2.medizin-common-list-mrg-3 {
  margin: 28px 0 0;
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style .medizin-common-list-2.medizin-common-list-mrg-3 {
    margin: 15px 0 0;
  }
}

.medizin-common-style-wrap .medizin-common-paragraph-style span {
  font-size: 15px;
  color: #444;
  font-style: italic;
  margin: 18px 0 0;
  display: block;
}

@media only screen and (max-width: 767px) {
  .medizin-common-style-wrap .medizin-common-paragraph-style span {
    margin: 7px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .purchase-guide-area.pb-35,
  .privacy-policy-area.pb-35,
  .terms-of-service-area.pb-35 {
    padding-bottom: 20px;
  }
}

/*------ 13. Breadcrumb style  --------*/
.breadcrumb-area-padding-1 {
  padding: 15px 0 55px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-area-padding-1 {
    padding: 25px 0 26px;
  }
}

.breadcrumb-area-padding-2 {
  padding: 20px 0 20px;
}

.breadcrumb-content.breadcrumb-content-mb1 {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content.breadcrumb-content-mb1 {
    margin: 0 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-content {
    text-align: center;
  }
}

.breadcrumb-content ul li {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  color: #4e97fd;
  margin-right: 18px;
  text-transform: capitalize;
  position: relative;
}

.breadcrumb-content ul li::before {
  position: absolute;
  width: 17px;
  height: 1px;
  background-color: #ababab;
  content: "";
  right: -20px;
  top: 14px;
  z-index: 9;
  transform: rotate(115deg);
}

.breadcrumb-content ul li:last-child::before {
  display: none;
}

.breadcrumb-content ul li:last-child {
  margin-right: 0;
}

.breadcrumb-content ul li a {
  color: #333;
  position: relative;
}

.breadcrumb-content ul li a::after {
  content: '';
  position: absolute;
  left: auto;
  right: 0;
  bottom: 0px;
  height: 1px;
  width: 0;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #4e97fd;
}

.breadcrumb-content ul li a:hover {
  color: #4e97fd;
}

.breadcrumb-content ul li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}

.breadcrumb-title h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  color: #333;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-title h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-title h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-title h1 {
    font-size: 25px;
  }
}

/*-------- 14. Sidebar style ---------*/
.sidebar-widget.sidebar-widget-wrap {
  background: #f8f8f8;
  border-radius: 5px;
}

.sidebar-widget.sidebar-widget-padding-1 {
  padding: 38px 20px 37px;
}

.sidebar-widget.sidebar-widget-padding-2 {
  padding: 38px 20px 40px;
}

.sidebar-widget.sidebar-widget-padding-3 {
  padding: 38px 20px 34px;
}

.sidebar-widget.sidebar-widget-padding-4 {
  padding: 38px 20px 25px;
}

.sidebar-widget.sidebar-widget-padding-5 {
  padding: 35px 20px 40px;
}

.sidebar-widget.sidebar-widget-padding-6 {
  padding: 38px 20px 27px;
}

.sidebar-widget h4.sidebar-widget-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 22px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget h4.sidebar-widget-title {
    margin-bottom: 15px;
  }
}

.sidebar-widget h4.sidebar-widget-title.widget-title-font-dec {
  font-size: 18px;
}

.sidebar-widget .sidebar-categories-list ul li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 11px;
}

.sidebar-widget .sidebar-categories-list ul li:last-child {
  margin-bottom: 0px;
}

.sidebar-widget .sidebar-categories-list ul li::before {
  content: '\f111';
  font-size: 4px;
  position: absolute;
  top: 2px;
  left: 0;
  font-weight: 900;
  display: inline-block;
  font-family: 'Font Awesome 5 Pro';
  position: absolute;
  color: #696969;
}

.sidebar-widget .sidebar-categories-list ul li a {
  color: #444;
  font-size: 15px;
}

.sidebar-widget .sidebar-categories-list ul li a span {
  color: #777;
}

.sidebar-widget .sidebar-categories-list ul li ul {
  margin-top: 8px;
}

.sidebar-widget .sidebar-categories-list ul li:hover > a {
  color: #4e97fd;
}

.sidebar-widget .sidebar-brand-list ul li,
.sidebar-widget .sidebar-sort-list ul li,
.sidebar-widget .sidebar-price-filter-list ul li {
  margin-bottom: 11px;
}

.sidebar-widget .sidebar-brand-list ul li:last-child,
.sidebar-widget .sidebar-sort-list ul li:last-child,
.sidebar-widget .sidebar-price-filter-list ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .sidebar-brand-list ul li a,
.sidebar-widget .sidebar-sort-list ul li a,
.sidebar-widget .sidebar-price-filter-list ul li a {
  color: #444;
  font-size: 15px;
}

.sidebar-widget .sidebar-brand-list ul li a span,
.sidebar-widget .sidebar-sort-list ul li a span,
.sidebar-widget .sidebar-price-filter-list ul li a span {
  color: #777;
}

.sidebar-widget .sidebar-brand-list ul li:hover > a,
.sidebar-widget .sidebar-sort-list ul li:hover > a,
.sidebar-widget .sidebar-price-filter-list ul li:hover > a {
  color: #4e97fd;
}

.sidebar-widget .price-filter {
  margin-top: 15px;
}

.sidebar-widget .price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

.sidebar-widget .price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #4e97fd none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #4e97fd none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 19px;
  margin-left: 0;
  width: 19px;
  margin-top: -2px;
}

.sidebar-widget .price-filter .price-slider-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 0;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  color: #333;
  height: auto;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-widget .price-filter .price-slider-amount button {
  background-color: #ffffff;
  border: medium none;
  color: #4e97fd;
  line-height: 1;
  padding: 10px 35px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  border-radius: 16px;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .sidebar-widget .price-filter .price-slider-amount button {
    padding: 10px 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .price-filter .price-slider-amount button {
    padding: 10px 15px;
  }
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #4e97fd;
  color: #ffffff;
}

.sidebar-widget .sidebar-rating-list ul li {
  margin: 0 0 11px;
}

.sidebar-widget .sidebar-rating-list ul li:last-child {
  margin: 0 0 0px;
}

.sidebar-widget .sidebar-rating-list ul li a i {
  color: #fb0;
  font-weight: 900;
  font-size: 15px;
  margin-right: 1px;
}

.sidebar-widget .sidebar-rating-list ul li a i.gray {
  color: #ababab;
  font-weight: 400;
}

.sidebar-widget .sidebar-rating-list ul li a span {
  color: #696969;
  margin-left: 2px;
  font-size: 15px;
}

.sidebar-widget.slidebar-product-wrap-2 {
  background-position: center center;
  background-repeat: repeat;
  background-size: auto;
  background-image: url(../../assets/images/bg/bg-1.jpg);
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-content-2 h3 {
  font-size: 18px;
  margin: 0 0 5px;
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-content-2 h2 {
  font-size: 24px;
  line-height: 1.5em;
  margin: 0;
  font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget.slidebar-product-wrap-2 .slidebar-product-content-2 h2 {
    font-size: 22px;
    line-height: 1.3em;
  }
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 {
  margin: 0px 0 10px;
  position: relative;
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 > a {
  display: block;
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 > a img {
  max-width: 100%;
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 .slidebar-pro-badge {
  width: 74px;
  height: 74px;
  line-height: 74px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffd55a;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 .slidebar-pro-badge {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 .slidebar-pro-badge h3 {
  color: #333;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 .slidebar-pro-badge h3 span {
  display: block;
  line-height: 1;
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 4px;
}

@media only screen and (max-width: 767px) {
  .sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 .slidebar-pro-badge h3 span {
    font-size: 18px;
  }
}

.sidebar-widget.slidebar-product-wrap-2 .slidebar-product-img-2 .slidebar-pro-badge.slidebar-pro-badge-position1 {
  position: absolute;
  top: 10%;
  right: 8%;
}

.sidebar-widget .product-highlight ul li {
  display: flex;
  align-items: center;
  margin: 0 0 14px;
}

.sidebar-widget .product-highlight ul li:last-child {
  margin: 0 0 0px;
}

.sidebar-widget .product-highlight ul li input {
  width: auto;
  height: auto;
}

.sidebar-widget .product-highlight ul li span {
  color: #444;
  font-size: 15px;
  margin-left: 16px;
  display: inline-block;
}

.sidebar-widget .sidebar-tag ul li {
  display: inline-block;
  margin: 0 7px 10px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-tag ul li {
    margin: 0 5px 8px 0;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-tag ul li {
    margin: 0 5px 8px 0;
  }
}

.sidebar-widget .sidebar-tag ul li a {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 26px;
  color: #666;
  padding: 4px 15px 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-tag ul li a {
    padding: 4px 13px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-widget .sidebar-tag ul li a {
    padding: 4px 13px 5px;
  }
}

.sidebar-widget .sidebar-tag ul li a:hover {
  background-color: #4e97fd;
  color: #ffffff;
}

.sidebar-color-list ul,
.pro-details-color-list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sidebar-color-list ul li,
.pro-details-color-list ul li {
  margin-right: 18px;
  margin-bottom: 13px;
  display: inline-block;
}

.sidebar-color-list ul li:last-child,
.pro-details-color-list ul li:last-child {
  margin-right: 0;
}

.sidebar-color-list ul li a,
.pro-details-color-list ul li a {
  width: 25px;
  height: 25px;
  font-size: 0;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #ccc;
  position: relative;
}

.sidebar-color-list ul li a.black,
.pro-details-color-list ul li a.black {
  background-color: #000000;
}

.sidebar-color-list ul li a.blue,
.pro-details-color-list ul li a.blue {
  background-color: #1e73be;
}

.sidebar-color-list ul li a.green,
.pro-details-color-list ul li a.green {
  background-color: #1ca54f;
}

.sidebar-color-list ul li a.pink,
.pro-details-color-list ul li a.pink {
  background-color: #ed9a9d;
}

.sidebar-color-list ul li a.powder-blue,
.pro-details-color-list ul li a.powder-blue {
  background-color: #94dde3;
}

.sidebar-color-list ul li a.purple,
.pro-details-color-list ul li a.purple {
  background-color: #9382dd;
}

.sidebar-color-list ul li a.red,
.pro-details-color-list ul li a.red {
  background-color: #dd3333;
}

.sidebar-color-list ul li a.transparent,
.pro-details-color-list ul li a.transparent {
  background-color: transparent;
}

.sidebar-color-list ul li a.white,
.pro-details-color-list ul li a.white {
  background-color: #fff;
}

.sidebar-color-list ul li a.yellow,
.pro-details-color-list ul li a.yellow {
  background-color: #eeee22;
}

.sidebar-color-list.pro-details-color-mrg ul li,
.pro-details-color-list.pro-details-color-mrg ul li {
  margin: 0 10px 0 0;
}

.tooltip-style-3 ul li a {
  position: relative;
}

.tooltip-style-3 ul li a:after {
  bottom: 100%;
  left: 50%;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #000;
  content: attr(aria-label);
  line-height: 1.3;
  transition-delay: .1s;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(0px);
}

.tooltip-style-3 ul li a:before {
  content: '';
  position: absolute;
  left: calc(50% - 7px);
  bottom: 100%;
  transition-delay: .1s;
  border: 7px solid transparent;
  border-top-color: #000;
  z-index: 9;
  margin-bottom: -13px;
  transition-delay: .1s;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
}

.tooltip-style-3 ul li a:hover {
  background-color: #4e97fd;
}

.tooltip-style-3 ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-8px);
}

.tooltip-style-3 ul li a:hover:before {
  opacity: 1;
  visibility: visible;
  transform: translateY(-8px);
}

.tooltip-style-4 a, .tooltip-style-4 button {
  position: relative;
}

.tooltip-style-4 a:after, .tooltip-style-4 button:after {
  bottom: 100%;
  left: 50%;
  position: absolute;
  white-space: nowrap;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  padding: 7px 10px;
  color: #ffffff;
  background-color: #4e97fd;
  content: attr(aria-label);
  line-height: 1.3;
  transition-delay: .1s;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(0px);
}

.tooltip-style-4 a:before, .tooltip-style-4 button:before {
  content: '';
  position: absolute;
  left: calc(50% - 7px);
  bottom: 100%;
  transition-delay: .1s;
  border: 7px solid transparent;
  border-top-color: #4e97fd;
  z-index: 9;
  margin-bottom: -13px;
  transition-delay: .1s;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  opacity: 0;
  visibility: hidden;
}

.tooltip-style-4 a:hover:after, .tooltip-style-4 button:hover:after {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-8px);
}

.tooltip-style-4 a:hover:before, .tooltip-style-4 button:hover:before {
  opacity: 1;
  visibility: visible;
  transform: translateY(-8px);
}

.tooltip-style-4.tooltip-style-4-black a:after, .tooltip-style-4.tooltip-style-4-black button:after {
  background-color: #000;
  white-space: normal;
  min-width: 120px;
  padding: 7px 10px 12px;
}

.tooltip-style-4.tooltip-style-4-black a:before, .tooltip-style-4.tooltip-style-4-black button:before {
  border-top-color: #000;
}

.shop-topbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 37px;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .totall-product {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .totall-product {
    margin-bottom: 0px;
  }
}

.shop-topbar-wrapper .totall-product p {
  font-size: 15px;
  color: #444;
}

.shop-topbar-wrapper .totall-product p span {
  color: #333;
  font-weight: 500;
}

.shop-topbar-wrapper .sort-by-product-area {
  position: relative;
}

.shop-topbar-wrapper .sort-by-product-area .sort-by-product-wrap {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  color: #777;
  padding: 11px 16px 11px 16px;
  border-radius: 26px;
  transition: all .3s ease 0s;
  cursor: pointer;
}

.shop-topbar-wrapper .sort-by-product-area .sort-by-product-wrap .sort-by {
  margin-right: 5px;
}

.shop-topbar-wrapper .sort-by-product-area .sort-by-product-wrap .sort-by i {
  margin-right: 16px;
  font-size: 16px;
  color: #ababab;
  position: relative;
  top: 2px;
}

.shop-topbar-wrapper .sort-by-product-area .sort-by-product-wrap .sort-by span {
  font-size: 15px;
  font-weight: 500;
}

.shop-topbar-wrapper .sort-by-product-area .sort-by-product-wrap .sort-by-dropdown-wrap span {
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.shop-topbar-wrapper .sort-by-product-area .sort-by-product-wrap .sort-by-dropdown-wrap span i {
  font-size: 18px;
  color: #333;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.shop-topbar-wrapper .sort-by-product-area:hover .sort-by-product-wrap {
  background-color: #ffffff;
  border: 1px solid #4e97fd;
}

.sort-by-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  right: 0;
  padding: 16px 0 21px;
  background: #f8f8f8;
  border: 0;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  min-width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  color: #333;
  font-weight: 500;
}

.sort-by-dropdown.show {
  opacity: 1;
  visibility: visible;
}

.sort-by-dropdown ul li {
  display: block;
}

.sort-by-dropdown ul li a {
  font-weight: 500;
  color: #333;
  font-size: 15px;
  padding: 5px 30px;
  display: block;
  position: relative;
}

.sort-by-dropdown ul li a.active::before {
  content: '\f00c';
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 12px;
  color: #4e97fd;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

.sort-by-dropdown ul li a:hover {
  background-color: #4e97fd;
  color: #ffffff;
}

.pro-pagination-style ul {
  display: flex;
  justify-content: center;
}

.pro-pagination-style ul li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border-radius: 100%;
  margin: 0 2px;
  color: #333;
}

.pro-pagination-style ul li a:hover {
  color: #4e97fd;
}

.pro-pagination-style ul li a.active {
  background-color: #4e97fd;
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-pagination-style.mt-55 {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-pagination-style.mt-55 {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-55 {
    margin-top: 0px;
  }
}

.sidebar-wrapper-mr1 {
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper-mr1 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper-mr1 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper-mr1 {
    margin-right: 0px;
  }
}

.sidebar-wrapper-ml1 {
  margin-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper-ml1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper-ml1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper-ml1 {
    margin-left: 0px;
  }
}

.sidebar-wrapper-ml1 {
  margin-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper-ml1 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper-ml1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper-ml1 {
    margin-left: 0px;
  }
}

.product-filter-wrapper {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 28px 30px 25px;
  margin: 0 0 58px;
  display: none;
}

.shop-filter a {
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  background-color: #4e97fd;
  position: relative;
  border-radius: 26px;
  padding: 11px 24px 10px;
}

.shop-filter a span {
  font-size: 16px;
  margin-right: 5px;
}

.shop-filter a i {
  margin-left: 14px;
}

.shop-filter a i.angle-up {
  position: absolute;
  right: 24px;
  top: 15px;
  opacity: 0;
  visibility: hidden;
}

.shop-filter a.active i.angle-down {
  opacity: 0;
  visibility: hidden;
}

.shop-filter a.active i.angle-up {
  opacity: 1;
  visibility: visible;
}

.shop-filter a:hover {
  background-color: #e4573d;
}

.widget-menu-style {
  border: 1px solid #eee;
}

.widget-menu-style nav ul li {
  border-bottom: 1px solid #eee;
  border-radius: 5px;
}

.widget-menu-style nav ul li:last-child {
  border-bottom: none;
}

.widget-menu-style nav ul li a {
  color: #444;
  font-size: 15px;
  position: relative;
  display: block;
  padding: 15px 30px;
}

.widget-menu-style nav ul li a::before {
  content: '\f111';
  font-size: 4px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 16px;
  font-family: 'Font Awesome 5 Pro';
  color: #ababab;
  font-weight: 900;
  transition: all .3s ease 0s;
}

.widget-menu-style nav ul li a:hover, .widget-menu-style nav ul li a.active {
  color: #ffffff;
  background-color: #4e97fd;
}

.widget-menu-style nav ul li a:hover::before, .widget-menu-style nav ul li a.active::before {
  color: #ffffff;
}

.single-sidebar-post {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.single-sidebar-post:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}

.single-sidebar-post .sidebar-post-img {
  flex: 0 0 100px;
  margin-right: 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-sidebar-post .sidebar-post-img {
    flex: 0 0 80px;
    margin-right: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .single-sidebar-post .sidebar-post-img {
    flex: 0 0 80px;
    margin-right: 12px;
  }
}

.single-sidebar-post .sidebar-post-img a {
  display: block;
}

.single-sidebar-post .sidebar-post-img a img {
  width: 100%;
}

.single-sidebar-post .sidebar-post-content h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.44;
}

.single-sidebar-post .sidebar-post-content h4 a {
  color: #333;
}

.single-sidebar-post .sidebar-post-content h4 a:hover {
  color: #4e97fd;
}

.single-sidebar-post .sidebar-post-content span {
  color: #444;
  margin-top: 6px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-top: 50px;
  }
  .sidebar-wrapper.sidebar-wrapper-mt-dec {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper {
    margin-top: 30px;
  }
  .sidebar-wrapper.sidebar-wrapper-mt-dec {
    margin-top: 20px;
  }
}

/*-------- 15. Product details style ---------*/
.product-dec-small-style1 {
  margin: 13px auto 0;
}

.product-dec-small-style1 .product-dec-small {
  margin: 0 5px 0px 5px;
  cursor: pointer;
}

.product-dec-small-style1 .product-dec-small img {
  width: 100%;
}

.product-dec-small-style1 .product-dec-small.slick-active img {
  border: 1px solid #eee;
  border-radius: 5px;
  transition: all .3s ease 0s;
}

.product-dec-small-style1 .product-dec-small.slick-active img:hover {
  border: 1px solid #4e97fd;
}

.product-dec-small-style1 .product-dec-small.slick-active.slick-current img {
  border: 1px solid #4e97fd;
}

.product-dec-small-style1 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style1 .slick-slide,
.product-dec-small-style2 .slick-slide {
  border: 1px solid transparent;
}

.single-big-img-style {
  position: relative;
}

.single-big-img-style .pro-details-big-img a {
  display: block;
}

.single-big-img-style .pro-details-big-img a img {
  width: 100%;
}

.single-big-img-style .pro-details-badges.product-badges-position {
  position: absolute;
  left: 0;
  top: 0;
}

.single-big-img-style .pro-details-badges span {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  border-radius: 2px;
  color: #ffffff;
  margin-right: 10px;
  padding: 5px 8px 6px;
}

.single-big-img-style .pro-details-badges span.red {
  background-color: #e4573d;
}

.product-details-content .pro-details-category ul {
  display: flex;
}

.product-details-content .pro-details-category ul li {
  color: #444;
  margin-right: 5px;
}

.product-details-content .pro-details-category ul li:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-category ul li a {
  color: #444;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.product-details-content .pro-details-category ul li a:hover {
  color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-category ul li a {
    letter-spacing: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-category ul li a {
    letter-spacing: 1px;
  }
}

.product-details-content h1 {
  font-size: 34px;
  margin: 12px 0 23px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-details-content h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h1 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h1 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content h1 {
    font-size: 24px;
    margin: 8px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content h1 {
    font-size: 25px;
  }
}

.product-details-content .pro-details-brand-review {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-brand-review {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-brand-review {
    display: flex;
  }
}

.product-details-content .pro-details-brand-review .pro-details-brand {
  margin-right: 36px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-brand-review .pro-details-brand {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-brand-review .pro-details-brand {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-brand-review .pro-details-brand {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-brand-review .pro-details-brand {
    margin-bottom: 0px;
  }
}

.product-details-content .pro-details-brand-review .pro-details-brand::before {
  content: '';
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 21px;
  background: #eee;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-brand-review .pro-details-brand::before {
    right: -8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-brand-review .pro-details-brand::before {
    right: -8px;
  }
}

.product-details-content .pro-details-brand-review .pro-details-brand span {
  color: #333;
}

.product-details-content .pro-details-brand-review .pro-details-brand span a {
  color: #4e97fd;
  display: inline-block;
  margin: 0 0 0 3px;
}

.product-details-content .pro-details-brand-review .pro-details-brand span a:hover {
  color: #e4573d;
}

.product-details-content .pro-details-brand-review .pro-details-rating-wrap {
  display: flex;
  align-items: center;
}

.product-details-content .pro-details-brand-review .pro-details-rating-wrap span {
  color: #333;
}

.product-details-content .pro-details-brand-review .pro-details-rating-wrap .pro-details-rating {
  display: flex;
  margin: 0 10px 0;
}

.product-details-content .pro-details-brand-review .pro-details-rating-wrap .pro-details-rating i {
  color: #fb0;
  font-size: 15px;
}

.product-details-content .pro-details-brand-review .pro-details-rating-wrap a {
  display: inline-block;
  font-size: 15px;
  color: #444;
}

.product-details-content .pro-details-price-short-description {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 29px 0 24px;
  margin: 18px 0 20px;
}

.product-details-content .pro-details-price-short-description .pro-details-price {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}

.product-details-content .pro-details-price-short-description .pro-details-price span {
  font-size: 24px;
  font-weight: 700;
}

.product-details-content .pro-details-price-short-description .pro-details-price span.new-price {
  color: #e4573d;
}

.product-details-content .pro-details-price-short-description .pro-details-price span.old-price {
  color: #666;
  font-weight: 400;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 5px;
}

.product-details-content .pro-details-price-short-description .pro-details-short-description p {
  font-size: 15px;
  color: #444;
  margin: 15px 0 0;
}

.product-details-content .pro-details-color-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product-details-content .pro-details-color-wrap span {
  font-size: 15px;
  color: #333;
  min-width: 85px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-color-wrap span {
    min-width: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-color-wrap span {
    min-width: 85px;
  }
}

.product-details-content .pro-details-quality-stock-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 25px 0 23px;
}

.product-details-content .pro-details-quality-stock-area span {
  font-size: 15px;
  color: #333;
  min-width: 85px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality-stock-area span {
    min-width: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content .pro-details-quality-stock-area span {
    min-width: 85px;
  }
}

.product-details-content .pro-details-quality-stock-area .pro-details-quality-stock-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.product-details-content .pro-details-quality-stock-area .pro-details-quality-stock-wrap .pro-details-stock {
  margin: 0 0 0 18px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-quality-stock-area .pro-details-quality-stock-wrap .pro-details-stock {
    margin: 0 0 0 12px;
  }
}

.product-details-content .pro-details-quality-stock-area .pro-details-quality-stock-wrap .pro-details-stock span {
  color: #444;
  font-size: 15px;
}

.product-details-content .pro-details-quality-stock-area .pro-details-quality-stock-wrap .pro-details-stock span i {
  color: #8cc63e;
  font-size: 14px;
  margin-right: 5px;
}

.product-details-content .pro-details-action-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-details-content .pro-details-action-wrap .pro-details-add-to-cart button {
  color: #ffffff;
  padding: 0;
  border: 0;
  font-size: 15px;
  font-weight: 700;
  border-radius: 26px;
  padding: 10px 41px 12px;
  background-color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-action-wrap .pro-details-add-to-cart button {
    padding: 10px 25px 12px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-add-to-cart button:hover {
  background-color: #e4573d;
}

.product-details-content .pro-details-action-wrap .pro-details-action {
  display: flex;
  margin-left: 12px;
}

.product-details-content .pro-details-action-wrap .pro-details-action button {
  position: relative;
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  color: #333;
  border-radius: 50%;
  font-size: 14px;
  padding: 0;
  border: none;
  margin-right: 12px;
}

.product-details-content .pro-details-action-wrap .pro-details-action button:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-action-wrap .pro-details-action button:hover {
  background-color: #4e97fd;
  color: #ffffff;
}

.product-details-content .product-details-meta {
  border-top: 1px solid #eee;
  margin: 25px 0 22px;
  padding: 18px 0 0;
}

.product-details-content .product-details-meta ul li {
  color: #444;
  font-size: 15px;
  margin: 0 0 4px;
}

.product-details-content .product-details-meta ul li:last-child {
  margin: 0 0 0px;
}

.product-details-content .product-details-meta ul li a {
  color: #444;
  font-size: 15px;
}

.product-details-content .product-details-meta ul li a:hover {
  color: #4e97fd;
}

.product-details-content .product-details-meta ul li span {
  font-size: 15px;
  min-width: 100px;
  color: #444;
  margin-right: 15px;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-meta ul li span {
    min-width: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-meta ul li span {
    min-width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-meta ul li span {
    min-width: 40px;
  }
}

.product-details-content .product-details-social {
  display: flex;
  flex-wrap: wrap;
}

.product-details-content .product-details-social a {
  font-size: 20px;
  display: inline-block;
  margin-right: 22px;
}

.product-details-content .product-details-social a:last-child {
  margin-right: 0;
}

.product-details-content .product-details-social a.facebook {
  color: #567fa9;
}

.product-details-content .product-details-social a.twitter {
  color: #26d0fe;
}

.product-details-content .product-details-social a.linkedin {
  color: #0077b5;
}

.product-details-content .product-details-social a.tumblr {
  color: #35465c;
}

.product-details-content .product-details-social a.envelope {
  color: #dd4b39;
}

.product-details-content.pro-details-content-pl {
  padding-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content.pro-details-content-pl {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content.pro-details-content-pl {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content.pro-details-content-pl {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    margin-top: 30px;
  }
}

.product-details-wrap-bottom {
  margin: 70px 0 0;
}

@media only screen and (max-width: 767px) {
  .product-details-wrap-bottom {
    margin: 50px 0 0;
  }
}

.product-details-wrap-bottom .entry-product-section-heading {
  margin-bottom: 29px;
}

.product-details-wrap-bottom .entry-product-section-heading h2 {
  font-size: 28px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .product-details-wrap-bottom .entry-product-section-heading h2 {
    font-size: 25px;
  }
}

.product-details-description p {
  font-size: 15px;
  line-height: 1.74;
  margin: 0 0 25px;
}

@media only screen and (max-width: 767px) {
  .product-details-description p {
    margin: 0 0 20px;
  }
}

.product-details-description ul {
  padding: 0 0 24px 14px;
}

.product-details-description ul li {
  font-size: 15px;
  color: #444;
  margin: 0 0 12px;
  list-style: disc;
}

.product-details-description ul li:last-child {
  margin: 0 0 0;
}

.additional-information ul {
  padding: 0 0 0px 14px;
}

.additional-information ul li {
  color: #444;
  font-size: 15px;
  display: flex;
  margin: 0 0 10px;
  position: relative;
}

.additional-information ul li:before {
  position: absolute;
  left: -14px;
  top: 9px;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #9b9b9b;
}

.additional-information ul li:last-child {
  margin: 0 0 0;
}

.additional-information ul li span {
  flex: 0 0 165px;
  color: #444;
  font-size: 15px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .additional-information ul li span {
    flex: 0 0 135px;
  }
}

.pro-details-review p {
  color: #444;
  font-size: 15px;
  margin: 0 0 29px;
}

.pro-details-review p span {
  font-size: 18px;
  font-weight: 700;
  color: #4e97fd;
}

.pro-details-review .single-pro-details-review {
  display: flex;
  margin: 0 0 30px;
  padding: 0 0 26px;
  border-bottom: 1px solid #eee;
}

.pro-details-review .single-pro-details-review .review-img {
  flex: 0 0 70px;
  margin-right: 22px;
}

@media only screen and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-img {
    margin-right: 15px;
  }
}

.pro-details-review .single-pro-details-review .review-img img {
  width: 100%;
}

.pro-details-review .single-pro-details-review .review-content .review-name-rating {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content .review-name-rating {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content .review-name-rating {
    display: flex;
  }
}

.pro-details-review .single-pro-details-review .review-content .review-name-rating .review-rating {
  display: flex;
}

.pro-details-review .single-pro-details-review .review-content .review-name-rating .review-rating i {
  color: #fb0;
  font-size: 15px;
  margin-right: 1px;
}

.pro-details-review .single-pro-details-review .review-content .review-name-rating .review-name {
  margin: 0 0 0 18px;
}

@media only screen and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content .review-name-rating .review-name {
    margin: 5px 0 0 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content .review-name-rating .review-name {
    margin: 0 0 0 18px;
  }
}

.pro-details-review .single-pro-details-review .review-content .review-name-rating .review-name h6 {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.pro-details-review .single-pro-details-review .review-content p {
  color: #444;
  font-size: 15px;
  margin: 14px 0 17px;
}

@media only screen and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content p {
    margin: 10px 0 10px;
  }
}

.pro-details-review .single-pro-details-review .review-content .review-date-btn {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content .review-date-btn {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .pro-details-review .single-pro-details-review .review-content .review-date-btn {
    display: flex;
  }
}

.pro-details-review .single-pro-details-review .review-content .review-date-btn .review-date {
  margin-right: 10px;
}

.pro-details-review .single-pro-details-review .review-content .review-date-btn .review-date span {
  color: #444;
  font-size: 15px;
}

.pro-details-review .single-pro-details-review .review-content .review-date-btn .review-btn a {
  display: inline-block;
  font-weight: 500;
  color: #333;
  font-size: 15px;
}

.pro-details-review .single-pro-details-review .review-content .review-date-btn .review-btn a:hover {
  color: #4e97fd;
}

.pro-details-review .ratting-form-wrapper h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.pro-details-review .ratting-form-wrapper p {
  font-size: 15px;
  color: #444;
  margin: 35px 0 20px;
}

.pro-details-review .ratting-form-wrapper .comment-form-rating-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 28px;
}

.pro-details-review .ratting-form-wrapper .comment-form-rating-wrap span {
  font-size: 15px;
  color: #444;
  display: inline-block;
  margin-right: 25px;
}

.pro-details-review .ratting-form-wrapper .comment-form-rating-wrap .comment-form-rating i {
  font-weight: 400;
  font-size: 18px;
  color: #ababab;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form input, .pro-details-review .ratting-form-wrapper .rating-form-style form textarea {
  border: 1px solid #f8f8f8;
  background-color: #f8f8f8;
  padding: 3px 20px;
  height: 48px;
  padding: 3px 20px;
  border-radius: 26px;
  color: #444;
  margin-bottom: 25px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pro-details-review .ratting-form-wrapper .rating-form-style form input:focus, .pro-details-review .ratting-form-wrapper .rating-form-style form textarea:focus {
  border: 1px solid #4e97fd;
  background-color: #ffffff;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form input::-webkit-input-placeholder, .pro-details-review .ratting-form-wrapper .rating-form-style form textarea::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form input::-moz-input-placeholder, .pro-details-review .ratting-form-wrapper .rating-form-style form textarea::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form textarea {
  height: 140px;
  padding: 19px 20px 3px;
  border-radius: 5px;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form .cookies-consent {
  display: flex;
  align-items: center;
  margin: 0 0 27px;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form .cookies-consent input {
  width: auto;
  height: auto;
  margin: 0;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form .cookies-consent p {
  color: #444;
  font-size: 15px;
  margin: 0 0 0 15px;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form .form-submit input {
  width: auto;
  font-weight: 700;
  border-radius: 26px;
  height: auto;
  color: #ffffff;
  background-color: #4e97fd;
  border: none;
  padding: 12px 54px 13px;
  margin: 0;
}

.pro-details-review .ratting-form-wrapper .rating-form-style form .form-submit input:hover {
  background-color: #e4573d;
}

.pro-details-mrg-tb {
  margin: 50px 0 50px;
}

.tab-style-1 {
  border-bottom: 1px solid #ebebeb;
}

.tab-style-1 a {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  margin-right: 45px;
  color: #696969;
  padding: 0 0 13px;
  border-bottom: 3px solid transparent;
}

@media only screen and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 15px 10px 0;
    padding: 0 0 6px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-1 a {
    margin: 0 30px 0px 0;
    padding: 0 0 6px;
    font-size: 18px;
  }
}

.tab-style-1 a:last-child {
  margin-right: 0px;
}

.tab-style-1 a.active {
  color: #333;
  border-bottom: 3px solid #4e97fd;
}

@media only screen and (max-width: 767px) {
  .tab-style-1.mb-35 {
    margin-bottom: 25px;
  }
}

.product-quality {
  position: relative;
  width: 110px;
}

.product-quality input {
  width: 110px;
  font-size: 15px;
  font-weight: 700;
  color: #333;
  padding: 2px 30px 2px 10px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  text-align: center;
  border-radius: 26px;
  height: 48px;
}

.product-quality .qtybutton {
  font-size: 0;
}

.product-quality .qtybutton.dec {
  position: absolute;
  width: 30px;
  height: 15px;
  text-align: center;
  right: 0;
  bottom: 7px;
}

.product-quality .qtybutton.dec::before {
  content: '';
  position: absolute;
  right: 14px;
  bottom: 8px;
  border-top: 5px solid #696969;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}

.product-quality .qtybutton.dec:hover::before {
  border-top: 5px solid #4e97fd;
}

.product-quality .qtybutton.inc {
  position: absolute;
  width: 30px;
  height: 15px;
  right: 0;
  top: 9px;
}

.product-quality .qtybutton.inc::after {
  content: '';
  position: absolute;
  right: 14px;
  top: 8px;
  border-bottom: 5px solid #696969;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}

.product-quality .qtybutton.inc:hover::after {
  border-bottom: 5px solid #4e97fd;
}

@media only screen and (max-width: 767px) {
  .pb-55 {
    padding-bottom: 40px;
  }
}

/*-------- 16. Cart style ---------*/
.cart-table-content .table-content {
  border: 1px solid #eee;
}

.cart-table-content .table-content table {
  width: 100%;
}

.cart-table-content .table-content table thead {
  background-color: #f8f8f8;
}

.cart-table-content .table-content table thead > tr th {
  color: #333;
  font-size: 15px;
  font-weight: 500;
  padding: 14px 15px 14px 0;
}

.cart-table-content .table-content table thead > tr th:first-child {
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th:first-child {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th:first-child {
    padding-left: 20px;
  }
}

.cart-table-content .table-content table thead > tr th.width-thumbnail {
  min-width: 130px;
}

.cart-table-content .table-content table thead > tr th.width-name {
  min-width: 356px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-name {
    min-width: 140px;
  }
}

.cart-table-content .table-content table thead > tr th.width-price {
  min-width: 118px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-price {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-quantity {
  min-width: 187px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-quantity {
    min-width: 140px;
  }
}

.cart-table-content .table-content table thead > tr th.width-subtotal {
  min-width: 107px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-subtotal {
    min-width: 100px;
  }
}

.cart-table-content .table-content table thead > tr th.width-remove {
  min-width: 101px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table thead > tr th.width-remove {
    min-width: 67px;
  }
}

.cart-table-content .table-content table tbody > tr {
  border-bottom: 1px solid #eee;
}

.cart-table-content .table-content table tbody > tr:last-child {
  border-bottom: none;
}

.cart-table-content .table-content table tbody > tr td {
  padding: 30px 0 30px 0;
}

.cart-table-content .table-content table tbody > tr td:first-child {
  padding-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content .table-content table tbody > tr td:first-child {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content .table-content table tbody > tr td:first-child {
    padding-left: 20px;
  }
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a {
  display: block;
}

.cart-table-content .table-content table tbody > tr td.product-thumbnail a img {
  width: 80px;
  border: 1px solid #eee;
}

.cart-table-content .table-content table tbody > tr td.product-name h5 {
  display: block;
  font-size: 15px;
  color: #333;
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a {
  color: #333;
}

.cart-table-content .table-content table tbody > tr td.product-name h5 a:hover {
  color: #4e97fd;
}

.cart-table-content .table-content table tbody > tr td.product-price span, .cart-table-content .table-content table tbody > tr td.product-total span {
  font-size: 15px;
  font-weight: 500;
  color: #4e97fd;
}

.cart-table-content .table-content table tbody > tr td.product-remove a {
  font-size: 15px;
  font-weight: 500;
  color: #696969;
  padding: 0 0 3px;
  display: inline-block;
  border-bottom: 1px solid #eee;
}

.cart-table-content .table-content table tbody > tr td.product-remove a:hover {
  color: #4e97fd;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 75px;
  margin-bottom: 70px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
    padding-bottom: 40px;
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: flex;
  }
}

.cart-shiping-update-wrapper .continure-clear-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .continure-clear-btn {
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-shiping-update-wrapper .continure-clear-btn {
    display: flex;
  }
}

.cart-shiping-update-wrapper .continure-clear-btn .clear-btn {
  margin-left: 22px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .continure-clear-btn .clear-btn {
    margin: 15px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-shiping-update-wrapper .continure-clear-btn .clear-btn {
    margin: 0px 0 0 12px;
  }
}

.cart-shiping-update-wrapper .continure-clear-btn .clear-btn a {
  font-size: 15px;
  font-weight: 700;
  color: #444;
  display: inline-block;
}

.cart-shiping-update-wrapper .continure-clear-btn .clear-btn a i {
  margin-right: 8px;
  color: #4e97fd;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-shiping-update-wrapper .continure-clear-btn .clear-btn a i {
    margin-right: 4px;
  }
}

.cart-shiping-update-wrapper .continure-clear-btn .clear-btn a:hover {
  color: #4e97fd;
}

.cart-shiping-update-wrapper .update-btn a {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 13px 36px;
  background-color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .update-btn a {
    padding: 10px 20px;
  }
}

.cart-shiping-update-wrapper .update-btn a:hover {
  background-color: #e4573d;
}

.coupon-wrap h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 15px;
}

.coupon-wrap .coupon-content p {
  color: #444;
  font-size: 15px;
  margin: 0;
}

.input-style.coupon-content-mrg {
  margin: 23px 0 25px;
}

@media only screen and (max-width: 767px) {
  .input-style.coupon-content-mrg {
    margin: 15px 0 20px;
  }
}

.input-style.input-style-mb {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .input-style.input-style-mb {
    margin: 0 0 15px;
  }
}

.input-style input {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 48px;
  color: #666;
  padding: 3px 20px;
  border-radius: 26px;
  font-size: 15px;
}

.input-style input::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

.input-style input::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.input-style input:focus {
  background-color: #ffffff;
  border: 1px solid #4e97fd;
}

.cart-collaterals-wrap h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 15px;
}

.cart-collaterals-wrap .collaterals-content p {
  color: #444;
  font-size: 15px;
  margin: 0 0 11px;
}

.cart-collaterals-wrap .collaterals-content p span {
  font-size: 18px;
  font-weight: 700;
  color: #4e97fd;
}

.select-style.select-style-mrg-1 {
  margin-bottom: 30px;
}

.select-style .select2-container--default {
  width: 100%;
  display: block;
}

.select-style .select2-container--default .select2-selection--single {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 48px;
  color: #666;
  border-radius: 26px;
  font-size: 15px;
  padding: 0;
  transition: all .0s ease 0s;
}

.select-style .select2-container--default .select2-selection--single[aria-expanded="true"] {
  background-color: #ffffff;
  border: 1px solid #4e97fd;
}

.select-style .select2-container--default .select2-selection--single span {
  height: 48px;
  line-height: 40px;
  color: #666;
  padding: 3px 20px;
  border-radius: 26px;
}

.select-style .select2-container--default.select2-container--above .select2-selection--single {
  border-top: none;
}

.select-style .select2-container--default.select2-container--below .select2-selection--single {
  border-bottom: none;
}

.select2-container .select2-dropdown {
  border: 1px solid #4e97fd;
}

.select2-container .select2-dropdown.select2-dropdown--below {
  border-radius: 0px 0px 26px 26px;
  border-top: none;
}

.select2-container .select2-dropdown.select2-dropdown--above {
  border-radius: 26px 26px 0px 0px;
  border-bottom: none;
}

.select2-container .select2-dropdown.select2-dropdown--above .select2-search--dropdown {
  margin: 7px 0 7px;
}

.select2-container .select2-dropdown .select2-search--dropdown {
  padding: 0px 20px 0px;
  margin: 0 0 7px;
  position: relative;
}

.select2-container .select2-dropdown .select2-search--dropdown::after {
  content: '\f002';
  font-size: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  font-weight: 900;
  font-family: 'Font Awesome 5 Pro';
  display: inline-block;
  cursor: pointer;
}

.select2-container .select2-dropdown .select2-search--dropdown:hover::after {
  color: #4e97fd;
}

.select2-container .select2-dropdown .select2-search--dropdown > input {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 40px;
  color: #666;
  padding: 3px 50px 3px 20px;
  border-radius: 26px;
  font-size: 15px;
}

.select2-container .select2-dropdown .select2-search--dropdown > input::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

.select2-container .select2-dropdown .select2-search--dropdown > input::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.select2-container .select2-dropdown .select2-search--dropdown > input:focus {
  background-color: #ffffff;
  border: 1px solid #4e97fd;
}

.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option {
  padding: 7px 20px;
  color: #333;
  font-size: 15px;
}

.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option.select2-results__option--highlighted {
  color: #ffffff;
  background-color: #4e97fd;
}

.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected="true"] {
  color: #ffffff;
  background-color: #4e97fd;
}

.continure-btn a,
.common-btn-style a {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 13px 36px;
  background-color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .continure-btn a,
  .common-btn-style a {
    padding: 10px 20px;
  }
}

.continure-btn a.common-btn-padding-2,
.common-btn-style a.common-btn-padding-2 {
  padding: 12px 41px;
}

.continure-btn a:hover,
.common-btn-style a:hover {
  background-color: #e4573d;
}

.grand-total-wrap {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 25px 30px 40px;
}

.grand-total-wrap ul {
  overflow: hidden;
  display: block;
}

.grand-total-wrap ul li {
  font-size: 15px;
  color: #444;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
}

.grand-total-wrap ul li h4 {
  font-size: 18px;
  font-weight: 700;
  color: #4e97fd;
  display: flex;
  margin: 0;
}

.grand-total-wrap ul li h4 span {
  margin: 0 5px 0 0;
  font-size: 15px;
  color: #444;
  font-weight: 400;
}

.grand-total-wrap .grand-total {
  padding: 25px 0 40px;
}

.grand-total-wrap .grand-total h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.grand-total-wrap .grand-total h4 span {
  float: right;
  font-size: 20px;
  color: #e4573d;
  font-weight: 700;
}

.grand-total-wrap .grand-total-btn a {
  display: block;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 13px 36px;
  background-color: #4e97fd;
  text-align: center;
}

.grand-total-wrap .grand-total-btn a:hover {
  background-color: #e4573d;
}

@media only screen and (max-width: 767px) {
  .cart-area.pb-35 {
    padding-bottom: 20px;
  }
}

/*-------- 17. Checkout style ---------*/
.customer-zone > p {
  font-size: 16px;
  color: #444;
  margin: 0 0 0px;
}

.customer-zone > p a {
  color: #4e97fd;
  display: inline-block;
  position: relative;
}

.customer-zone > p a::before, .customer-zone > p a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  transform-origin: right;
  transform: scaleX(1) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
}

.customer-zone > p a::after {
  transform-origin: left;
  transform: scaleX(0) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.customer-zone > p a:hover::before {
  background-color: #4E97FD;
  transform: scaleX(0) translateZ(0);
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.customer-zone > p a:hover::after {
  background-color: #4E97FD;
  transform: scaleX(1) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
}

.customer-zone .checkout-login-info {
  display: none;
  margin: 20px 0 0;
}

.customer-zone .checkout-login-info p {
  color: #444;
  font-size: 15px;
  line-height: 1.74;
  margin: 0 0 20px;
}

.customer-zone .checkout-login-info .sin-checkout-login label {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #444;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .customer-zone .checkout-login-info .sin-checkout-login.mrg-small-device {
    margin: 0 0 25px;
  }
}

.customer-zone .checkout-login-info .button-remember-wrap {
  display: flex;
  align-items: center;
  margin: 30px 0 24px;
}

.customer-zone .checkout-login-info .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin-right: 10px;
}

.customer-zone .checkout-login-info .button-remember-wrap .checkout-login-toggle-btn input {
  width: auto;
  height: auto;
}

.customer-zone .checkout-login-info .button-remember-wrap .checkout-login-toggle-btn label {
  color: #444;
  font-size: 15px;
  margin: 0 0 0 12px;
}

.customer-zone .checkout-login-info .button-remember-wrap button {
  border: none;
  padding: 0;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 41px;
  color: #ffffff;
  border-radius: 26px;
  background-color: #4e97fd;
}

.customer-zone .checkout-login-info .button-remember-wrap button:hover {
  background-color: #e4573d;
}

.customer-zone .checkout-login-info .lost-password a {
  color: #444;
  font-size: 15px;
}

.customer-zone .checkout-login-info .lost-password a:hover {
  color: #4e97fd;
}

.customer-zone .checkout-login-info3 {
  width: 370px;
  margin: 27px 0 0;
  display: none;
}

@media only screen and (max-width: 767px) {
  .customer-zone .checkout-login-info3 {
    width: 100%;
  }
}

.customer-zone .checkout-login-info3 form {
  position: relative;
}

.customer-zone .checkout-login-info3 form input {
  padding: 3px 70px 3px 20px;
}

.customer-zone .checkout-login-info3 form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding: 2px 20px;
  font-size: 22px;
  border: none;
  background-color: transparent;
  color: #4e97fd;
  height: 100%;
  border-radius: 26px;
}

.customer-zone .checkout-login-info3 form button:hover {
  color: #ffffff;
  background-color: #e4573d;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin: 0 0 15px;
  }
}

.billing-info-wrap .billing-info label,
.billing-info-wrap .checkout-account-toggle label,
.billing-info-wrap .billing-select label {
  color: #444;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 8px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap .billing-info label,
  .billing-info-wrap .checkout-account-toggle label,
  .billing-info-wrap .billing-select label {
    margin: 0 0 5px;
  }
}

.billing-info-wrap .billing-info input.billing-address,
.billing-info-wrap .checkout-account-toggle input.billing-address,
.billing-info-wrap .billing-select input.billing-address {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap .billing-info input.billing-address,
  .billing-info-wrap .checkout-account-toggle input.billing-address,
  .billing-info-wrap .billing-select input.billing-address {
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap .billing-info.mb-35,
  .billing-info-wrap .checkout-account-toggle.mb-35,
  .billing-info-wrap .billing-select.mb-35 {
    margin-bottom: 25px;
  }
}

.billing-info-wrap .billing-select {
  display: block;
}

.billing-info-wrap .billing-select .select2-container--default {
  width: 100%;
}

.billing-info-wrap .billing-select .select2-container--default .selection {
  width: 100%;
}

.billing-info-wrap .checkout-account {
  display: flex;
  align-items: center;
}

.billing-info-wrap .checkout-account > input {
  width: auto;
  height: auto;
}

.billing-info-wrap .checkout-account > span {
  color: #444;
  font-size: 15px;
  margin-left: 10px;
}

.billing-info-wrap .different-address {
  display: flex;
  align-items: center;
}

.billing-info-wrap .different-address > input {
  width: auto;
  height: auto;
}

.billing-info-wrap .different-address > span {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
}

.billing-info-wrap .additional-info-wrap {
  margin: 40px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #444;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 8px;
  display: block;
}

.billing-info-wrap .additional-info-wrap textarea {
  height: 100px;
  resize: vertical;
  background: #f5f5f5;
  border: 2px solid #f5f5f5;
  padding: 10px;
  font-size: 15px;
  color: #666;
}

.billing-info-wrap .additional-info-wrap textarea:focus {
  background-color: transparent;
  border: 2px solid #4FC1F0;
}

.billing-info-wrap .checkout-account-toggle {
  margin-top: 30px;
}

.billing-info-wrap .checkout-account-toggle {
  display: none;
}

.billing-info-wrap .different-address.open-toggle {
  display: none;
}

.payment-details-area {
  border-top: 1px solid #eee;
  margin-top: 50px;
  padding-top: 45px;
}

@media only screen and (max-width: 767px) {
  .payment-details-area {
    margin-top: 40px;
    padding-top: 35px;
  }
}

.payment-details-area h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 37px;
}

@media only screen and (max-width: 767px) {
  .payment-details-area h4 {
    margin: 0 0 30px;
  }
}

.payment-details-area .payment-method {
  margin: 17px 0 0;
}

.payment-details-area .payment-method .sin-payment input {
  display: none;
}

.payment-details-area .payment-method .sin-payment label {
  padding: 13px 20px;
  border: 1px solid #e0e0e0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.payment-details-area .payment-method .sin-payment label span {
  width: 62px;
  margin-right: 11px;
  position: relative;
}

.payment-details-area .payment-method .sin-payment label span .nomal-img {
  opacity: 1;
  visibility: visible;
}

.payment-details-area .payment-method .sin-payment label span .active-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.payment-details-area .payment-method .sin-payment .payment-box {
  margin: 24px 0 22px;
  display: none;
}

.payment-details-area .payment-method .sin-payment .payment-box p {
  font-size: 15px;
  color: #444;
  margin: 0;
}

.payment-details-area .payment-method .sin-payment.payment-selected label {
  background: #edf4fe;
  border: 1px solid #edf4fe;
  color: #387aff;
}

.payment-details-area .payment-method .sin-payment.payment-selected label span .nomal-img {
  opacity: 0;
  visibility: hidden;
}

.payment-details-area .payment-method .sin-payment.payment-selected label span .active-img {
  opacity: 1;
  visibility: visible;
}

.order-summary {
  background: #f8f8f8;
  padding: 36px 30px 34px;
  margin-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-summary {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .order-summary {
    margin-left: 0px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .order-summary {
    margin-left: 0px;
    margin-top: 30px;
    padding: 36px 15px 34px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .order-summary {
    padding: 36px 30px 34px;
  }
}

.order-summary .order-summary-title h3 {
  font-size: 20px;
  font-weight: 500;
  padding: 0 0 20px;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.order-summary .order-summary-top .order-summary-img-price {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-img-title {
  display: flex;
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-img {
  flex: 0 0 80px;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-img {
    flex: 0 0 70px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-img {
    flex: 0 0 80px;
    margin-right: 20px;
  }
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-img a {
  display: block;
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-img a img {
  width: 100%;
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-title h4 {
  font-size: 15px;
  margin: 0;
  width: 60%;
  line-height: 1.74;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-title h4 {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-title h4 {
    width: 95%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-title h4 {
    width: 60%;
  }
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-img-title .order-summary-title h4 span {
  color: #9b9b9b;
  font-weight: 700;
}

.order-summary .order-summary-top .order-summary-img-price .order-summary-price span {
  font-weight: 700;
  font-size: 15px;
  color: #4e97fd;
}

.order-summary .order-summary-middle ul {
  overflow: hidden;
  display: block;
}

.order-summary .order-summary-middle ul li {
  font-size: 15px;
  color: #444;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
}

.order-summary .order-summary-middle ul li h4 {
  font-size: 18px;
  font-weight: 700;
  color: #4e97fd;
  display: block;
  text-align: right;
  margin: 0;
}

.order-summary .order-summary-middle ul li h4 span {
  margin: 0 5px 0 0;
  font-size: 15px;
  color: #444;
  display: block;
  font-weight: 400;
}

.order-summary .order-summary-middle ul li h4 span.price {
  color: #444;
  font-weight: 700;
  font-size: 15px;
}

.order-summary .order-summary-bottom h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0 0;
}

.order-summary .order-summary-bottom h4 span {
  float: right;
  font-size: 20px;
  color: #e4573d;
  font-weight: 700;
}

/*-------- 18. Wishlist style ---------*/
.wishlist-table-content .table-content {
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.wishlist-table-content .table-content table {
  width: 100%;
}

.wishlist-table-content .table-content table tbody > tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  transition: all .3s ease 0s;
}

.wishlist-table-content .table-content table tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.wishlist-table-content .table-content table tbody > tr:last-child {
  border-bottom: none;
}

.wishlist-table-content .table-content table tbody > tr td {
  text-align: center;
  padding: 17px 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td {
    padding: 15px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td {
    padding: 15px 10px;
  }
}

.wishlist-table-content .table-content table tbody > tr td:last-child {
  border-right: none;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-thumbnail {
  width: 128px;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-thumbnail a i {
  font-size: 16px;
  color: #9b9b9b;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-thumbnail a i:hover {
  color: #e4573d;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-img {
  width: 90px;
  padding: 10px 5px;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-img a {
  display: block;
  width: 79px;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-img a img {
  width: 100%;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info {
  width: 692px;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-product-info {
    min-width: 200px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-product-info {
    min-width: 180px;
  }
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info h5 {
  font-weight: 700;
  font-size: 15px;
  margin: 0;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info h5 a {
  color: #333;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info h5 a:hover {
  color: #4e97fd;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info .wishlist-product-price {
  margin: 5px 0 5px;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info .wishlist-product-price span {
  font-weight: 700;
  color: #4e97fd;
  font-size: 18px;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info .wishlist-product-price span.old-price {
  color: #777;
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
  text-decoration: line-through;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-info > span {
  color: #444;
  font-size: 15px;
  display: block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-product-info > span {
    line-height: 1.5;
  }
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-add-wrap {
  width: 507px;
}

@media only screen and (max-width: 767px) {
  .wishlist-table-content .table-content table tbody > tr td.wishlist-product-add-wrap {
    min-width: 200px;
  }
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-add-wrap span {
  color: #444;
  font-size: 15px;
  display: block;
  margin: 0 0 6px;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-add-wrap .wishlist-product-add a {
  display: inline-block;
  padding: 14px 20px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 26px;
  line-height: 1;
  color: #ffffff;
  background-color: #4e97fd;
}

.wishlist-table-content .table-content table tbody > tr td.wishlist-product-add-wrap .wishlist-product-add a:hover {
  background-color: #e4573d;
}

.wishlist-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-bottom {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom {
    display: block;
    margin-top: 25px;
  }
}

.wishlist-bottom .wishlist-social-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .wishlist-bottom .wishlist-social-wrap {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-social-wrap {
    margin-bottom: 10px;
  }
}

.wishlist-bottom .wishlist-social-wrap span {
  color: #666;
  font-size: 15px;
  display: inline-block;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-social-wrap span {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .wishlist-bottom .wishlist-social-wrap span {
    margin-right: 10px;
  }
}

.wishlist-bottom .wishlist-social-wrap .wishlist-social a {
  color: #333;
  font-size: 15px;
  margin-right: 8px;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-social-wrap .wishlist-social a {
    margin-right: 3px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .wishlist-bottom .wishlist-social-wrap .wishlist-social a {
    margin-right: 8px;
  }
}

.wishlist-bottom .wishlist-social-wrap .wishlist-social a:hover {
  color: #4e97fd;
}

.wishlist-bottom .wishlist-social-wrap .wishlist-social a:last-child {
  margin-right: 0px;
}

.wishlist-bottom .wishlist-link {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-link {
    display: block;
  }
}

.wishlist-bottom .wishlist-link span {
  color: #666;
  font-size: 15px;
  display: inline-block;
  margin-right: 10px;
}

.wishlist-bottom .wishlist-link .wishlist-input-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-link .wishlist-input-wrap {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-link .wishlist-input-wrap .wishlist-input {
    margin: 10px 0 10px;
  }
}

.wishlist-bottom .wishlist-link .wishlist-input-wrap .wishlist-input input {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  color: #666;
  height: 48px;
  font-size: 15px;
  border-radius: 26px;
  padding: 3px 20px;
  width: 232px;
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-link .wishlist-input-wrap .wishlist-input input {
    width: 100%;
  }
}

.wishlist-bottom .wishlist-link .wishlist-input-wrap .wishlist-btn {
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .wishlist-bottom .wishlist-link .wishlist-input-wrap .wishlist-btn {
    margin-left: 0px;
  }
}

.wishlist-bottom .wishlist-link .wishlist-input-wrap .wishlist-btn a {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 26px;
  padding: 0 41px;
  padding: 9px 41px 13px;
  background-color: #4e97fd;
}

/*-------- 19. About us style ---------*/
.about-us-img img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 23px 26px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 9;
}

.about-us-content {
  margin: 0 0 0 90px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content {
    margin: 0 0 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content {
    margin: 0 0 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content {
    margin: 50px 0 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content {
    margin: 30px 0 0 0px;
  }
}

.about-us-content .total-years {
  display: flex;
  align-items: flex-end;
}

.about-us-content .total-years h2 {
  font-size: 200px;
  font-weight: 900;
  line-height: .8em;
  color: #4e97fd;
  margin: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-content .total-years h2 {
    font-size: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content .total-years h2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content .total-years h2 {
    font-size: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content .total-years h2 {
    font-size: 80px;
  }
}

.about-us-content .total-years h4 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 2em;
  letter-spacing: 2px;
  color: #444;
  margin: 0 0 0 12px;
}

.about-us-content h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 25px 0 28px;
}

@media only screen and (max-width: 767px) {
  .about-us-content h3 {
    font-size: 18px;
    line-height: 1.5;
    margin: 18px 0 18px;
  }
}

.about-us-content p {
  font-size: 18px;
  color: #444;
  line-height: 1.67em;
  margin: 0 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content p {
    font-size: 16px;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content p {
    color: #555;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content p {
    font-size: 16px;
    margin: 0 0 20px;
    color: #555;
  }
}

.about-us-content .about-btn a {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  border-radius: 26px;
  padding: 0 36px;
  padding: 13px 36px;
  background-color: #edf4fe;
  color: #4e97fd;
}

.about-us-content .about-btn a i {
  font-size: 16px;
  margin-left: 10px;
}

.about-us-content .about-btn a:hover {
  color: #ffffff;
  background-color: #4e97fd;
}

.about-us-img {
  background-image: url("../../assets/images/bg/bg-2.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: auto;
}

.medizin-box {
  padding: 32px 37px 36px;
  background: #ffffff;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .medizin-box {
    padding: 32px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .medizin-box {
    padding: 32px 15px 20px;
  }
}

.medizin-box .icon-box-wrapper .medizin-icon-wrap svg {
  width: 56px;
  height: 56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .medizin-box .icon-box-wrapper .medizin-icon-wrap svg {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .medizin-box .icon-box-wrapper .medizin-icon-wrap svg {
    width: 50px;
    height: 50px;
  }
}

.medizin-box .icon-box-wrapper .icon-box-content .title {
  margin: 25px 0 23px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .medizin-box .icon-box-wrapper .icon-box-content .title {
    margin: 20px 0 17px;
  }
}

@media only screen and (max-width: 767px) {
  .medizin-box .icon-box-wrapper .icon-box-content .title {
    margin: 20px 0 17px;
  }
}

.medizin-box .icon-box-wrapper .icon-box-content .title h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .medizin-box .icon-box-wrapper .icon-box-content .title h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .medizin-box .icon-box-wrapper .icon-box-content .title h3 {
    font-size: 20px;
  }
}

.medizin-box .icon-box-wrapper .icon-box-content ul {
  padding-left: 15px;
}

.medizin-box .icon-box-wrapper .icon-box-content ul li {
  color: #444;
  font-size: 15px;
  list-style: disc;
  margin: 0 0 13px;
}

.medizin-box:hover {
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.funfact-area {
  background-color: #497bc2;
}

.funfact-area .single-funfact h2 {
  font-size: 64px;
  color: #ffffff;
  margin: 0 0 22px;
  line-height: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .funfact-area .single-funfact h2 {
    font-size: 55px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-area .single-funfact h2 {
    font-size: 50px;
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .funfact-area .single-funfact h2 {
    font-size: 45px;
    margin: 0 0 5px;
  }
}

.funfact-area .single-funfact span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 2em;
  letter-spacing: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-area .single-funfact span {
    letter-spacing: 1px;
  }
}

.team-wrap .team-img {
  position: relative;
  overflow: hidden;
}

.team-wrap .team-img img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.team-wrap .team-img .team-social {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.team-wrap .team-img .team-social a {
  display: inline-block;
  color: #696969;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  background-color: #ffffff;
  margin: 0 2px;
}

.team-wrap .team-img .team-social a:hover {
  background-color: #4e97fd;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .team-wrap .team-img.mb-25 {
    margin-bottom: 10px;
  }
}

.team-wrap .team-info h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 8px;
}

@media only screen and (max-width: 767px) {
  .team-wrap .team-info h3 {
    margin: 0 0 5px;
  }
}

.team-wrap .team-info span {
  font-size: 15px;
  color: #555;
  display: block;
}

.team-wrap:hover .team-social {
  opacity: 1;
  visibility: visible;
}

.team-wrap:hover .team-img img {
  transform: scale3d(1.1, 1.1, 1.1);
}

@media only screen and (max-width: 767px) {
  .team-wrap.mb-45 {
    margin-bottom: 30px;
  }
}

.about-us-area {
  position: relative;
}

.about-us-area .mouse-scroll-area-2 {
  position: absolute;
  top: 0% !important;
  left: 0 !important;
  z-index: 8 !important;
  width: 100% !important;
  height: 100% !important;
}

.about-us-area .mouse-scroll-area-2 .about-us-shape-1 {
  position: absolute;
  top: 69% !important;
  left: 15% !important;
  z-index: 8;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .about-us-area .mouse-scroll-area-2 .about-us-shape-1 {
    top: 75% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-area .mouse-scroll-area-2 .about-us-shape-1 {
    top: 50% !important;
    left: 55% !important;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-area .mouse-scroll-area-2 .about-us-shape-1 {
    top: 38% !important;
    left: 55% !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-us-area .mouse-scroll-area-2 .about-us-shape-1 {
    top: 50% !important;
    left: 50% !important;
  }
}

.about-us-area .mouse-scroll-area-2 .about-us-shape-1 .medizin-shape {
  width: 150px;
  height: 150px;
  color: #4e97fd;
  border-radius: 50%;
  background: currentColor;
}

@media only screen and (max-width: 767px) {
  .about-us-area .mouse-scroll-area-2 .about-us-shape-1 .medizin-shape {
    width: 100px;
    height: 100px;
  }
}

.return-policy-content .return-policy-img {
  margin: 0 0 29px;
}

@media only screen and (max-width: 767px) {
  .return-policy-content .return-policy-img {
    margin: 0 0 19px;
  }
}

.return-policy-content .return-policy-img img {
  width: 100%;
}

.return-policy-content h2 {
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .return-policy-content h2 {
    font-size: 30px;
    margin: 0 0 10px;
  }
}

.return-policy-content p {
  line-height: 1.74;
  font-size: 15px;
  margin: 0 0 25px;
}

@media only screen and (max-width: 767px) {
  .return-policy-content p {
    margin: 0 0 15px;
  }
}

.return-policy-content p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .mision-area.pb-70 {
    padding-bottom: 30px;
  }
  .funfact-area.pb-60 {
    padding-bottom: 25px;
  }
  .team-area.pb-40 {
    padding-bottom: 20px;
  }
  .about-us-area.pt-65 {
    padding-top: 50px;
  }
  .mision-area.pt-65 {
    padding-top: 50px;
  }
  .mision-area.pb-45 {
    padding-bottom: 30px;
  }
  .testimonial-area.pt-65 {
    padding-top: 55px;
  }
  .testimonial-area.pb-65 {
    padding-bottom: 40px;
  }
  .team-area.pt-65 {
    padding-top: 55px;
  }
  .team-area.pb-65 {
    padding-bottom: 40px;
  }
}

/*-------- 20. 404 style ---------*/
.error-content .error-logo {
  margin: 0 0 40px;
}

.error-content .error-logo a {
  display: block;
}

.error-content .error-logo a img {
  width: 166px;
}

@media only screen and (max-width: 767px) {
  .error-content .error-logo {
    margin: 0 0 20px;
  }
}

.error-content .error-img img {
  max-width: 100%;
}

.error-content h2 {
  font-size: 40px;
  font-weight: 500;
  margin: 22px 0 26px;
}

@media only screen and (max-width: 767px) {
  .error-content h2 {
    font-size: 22px;
    line-height: 32px;
    margin: 12px 0 10px;
  }
}

.error-content p {
  font-size: 18px;
  color: #444;
  line-height: 1.67;
  margin: 0px 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .error-content p {
    margin: 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content p {
    margin: 0px 80px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .error-content p {
    font-size: 17px;
    line-height: 1.57;
    margin: 0px 0 20px;
  }
}

.error-content .search-style-4 {
  margin: 0 50px;
}

@media only screen and (max-width: 767px) {
  .error-content .search-style-4 {
    margin: 0 0px;
  }
}

.error-content .search-style-4 form {
  position: relative;
}

.error-content .search-style-4 form input {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  color: #444;
  height: 48px;
  font-size: 15px;
  border-radius: 26px;
  padding: 3px 150px 3px 20px;
}

@media only screen and (max-width: 767px) {
  .error-content .search-style-4 form input {
    padding: 3px 140px 3px 20px;
  }
}

.error-content .search-style-4 form input::-moz-input-placeholder {
  color: #444;
  opacity: 1;
}

.error-content .search-style-4 form input::-webkit-input-placeholder {
  color: #444;
  opacity: 1;
}

.error-content .search-style-4 form input:focus {
  border: 1px solid #4e97fd;
}

.error-content .search-style-4 form button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 15px;
  font-weight: 700;
  border: none;
  height: 100%;
  color: #ffffff;
  padding: 5px 41px;
  border-radius: 26px;
  background-color: #4e97fd;
}

@media only screen and (max-width: 767px) {
  .error-content .search-style-4 form button {
    padding: 5px 35px;
  }
}

.error-content .search-style-4 form button:hover {
  background-color: #e4573d;
}

/*-------- 21. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #eee;
  border-bottom: none;
  font-weight: 500;
  font-size: 16px;
  display: block;
  color: #444;
  padding: 15px 30px;
  text-transform: capitalize;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #eee;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #4e97fd;
  border-color: #4e97fd;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content .welcome p {
  font-size: 15px;
  margin: 0 0 23px;
}

.myaccount-content .welcome p a {
  color: #444;
}

.myaccount-content .welcome p a:hover {
  color: #4e97fd;
}

.myaccount-content .welcome p strong {
  font-weight: 700;
}

.myaccount-content p {
  font-size: 15px;
  line-height: 1.74;
}

.myaccount-content p a {
  color: #444;
}

.myaccount-content p a:hover {
  color: #4e97fd;
}

.myaccount-content .account-details-form .account-info label {
  font-size: 15px;
  color: #444;
  margin: 0 0 3px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content .account-details-form .account-info.mb-30 {
    margin-bottom: 15px;
  }
}

.myaccount-content .account-details-form span {
  font-style: italic;
  font-size: 15px;
  color: #444;
  margin: 5px 0 0;
  display: block;
}

.myaccount-content .account-details-form fieldset {
  padding: 1.55em 1.625em 3em;
  border: 1px solid #eee;
  margin: 30px 0 36px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content .account-details-form fieldset {
    padding: 1.55em 1em 3em;
  }
}

.myaccount-content .account-details-form fieldset legend {
  font-size: 18px;
  width: auto;
  float: inherit;
}

.myaccount-content .account-details-form .account-info-btn button {
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  background-color: #4e97fd;
  border-radius: 26px;
  padding: 11px 41px 13px;
  border: none;
}

.myaccount-content .account-details-form .account-info-btn button:hover {
  background-color: #e4573d;
}

.myaccount-content.myaccount-address .myaccount-address-wrap {
  margin: 20px 0 0px;
}

.myaccount-content.myaccount-address .myaccount-address-wrap h3 {
  font-size: 25px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .myaccount-content.myaccount-address .myaccount-address-wrap h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-content.myaccount-address .myaccount-address-wrap h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .myaccount-content.myaccount-address .myaccount-address-wrap h3 {
    font-size: 20px;
  }
}

.myaccount-content.myaccount-address .myaccount-address-wrap .myaccount-address-content {
  margin: 20px 0 0;
}

@media only screen and (max-width: 767px) {
  .myaccount-content.myaccount-address .myaccount-address-wrap .myaccount-address-content {
    margin: 14px 0 0;
  }
}

.myaccount-content.myaccount-address .myaccount-address-wrap .myaccount-address-content h4 {
  font-size: 18px;
  font-size: 500;
  margin: 0 0 5px;
}

.myaccount-content.myaccount-address .myaccount-address-wrap .myaccount-address-content p {
  font-size: 15px;
  color: #444;
  line-height: 1.74;
  margin: 0;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #eee;
  border-bottom: 0;
  font-size: 15px;
  color: #333;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #eee;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 22. Login register style ---------*/
.login-register-wrap.login-register-gray-bg {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 29px 50px 48px;
  margin: 0 100px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrap.login-register-gray-bg {
    margin: 0 30px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-register-wrap.login-register-gray-bg {
    margin: 0 0px 40px 0;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrap.login-register-gray-bg {
    margin: 0 0px 40px 0;
    padding: 29px 20px 48px;
  }
}

.login-register-wrap .login-register-title h1 {
  font-size: 34px;
  font-weight: 500;
  margin: 0 0 21px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-title h1 {
    font-size: 30px;
  }
}

.login-register-wrap .login-register-form form .login-register-input-style {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form form .login-register-input-style {
    margin: 0 0 20px;
  }
}

.login-register-wrap .login-register-form form .login-register-input-style label {
  display: block;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #444;
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form form .login-register-input-style label {
    margin-bottom: 4px;
  }
}

.login-register-wrap .login-register-form form .login-register-input-style.input-style-white input {
  background-color: #ffffff;
}

.login-register-wrap .login-register-form form .lost-remember-wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form form .lost-remember-wrap {
    display: block;
  }
}

.login-register-wrap .login-register-form form .lost-remember-wrap .remember-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form form .lost-remember-wrap .remember-wrap {
    margin: 0 0 6px;
  }
}

.login-register-wrap .login-register-form form .lost-remember-wrap .remember-wrap input {
  width: auto;
  height: auto;
}

.login-register-wrap .login-register-form form .lost-remember-wrap .remember-wrap span {
  display: block;
  font-size: 15px;
  color: #444;
  margin-left: 15px;
}

.login-register-wrap .login-register-form form .lost-remember-wrap .lost-wrap a {
  font-size: 15px;
  color: #4e97fd;
  position: relative;
  display: inline-block;
}

.login-register-wrap .login-register-form form .lost-remember-wrap .lost-wrap a::before, .login-register-wrap .login-register-form form .lost-remember-wrap .lost-wrap a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  transform-origin: right;
  transform: scaleX(1) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
}

.login-register-wrap .login-register-form form .lost-remember-wrap .lost-wrap a::after {
  transform-origin: left;
  transform: scaleX(0) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-register-wrap .login-register-form form .lost-remember-wrap .lost-wrap a:hover::before {
  background-color: #4E97FD;
  transform: scaleX(0) translateZ(0);
  transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-register-wrap .login-register-form form .lost-remember-wrap .lost-wrap a:hover::after {
  background-color: #4E97FD;
  transform: scaleX(1) translateZ(0);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
}

.login-register-wrap .login-register-form form .login-register-btn {
  margin: 35px 0 0;
}

@media only screen and (max-width: 767px) {
  .login-register-wrap .login-register-form form .login-register-btn {
    margin: 25px 0 0;
  }
}

.login-register-wrap .login-register-form form .login-register-btn button {
  font-size: 15px;
  font-weight: 700;
  border: none;
  background-color: #4e97fd;
  color: #ffffff;
  display: block;
  text-align: center;
  border-radius: 26px;
  width: 100%;
  padding: 11px 10px 13px;
}

.login-register-wrap .login-register-form form .login-register-btn button:hover {
  background-color: #e4573d;
}

.login-register-wrap .login-register-form form .privacy-policy-wrap p {
  color: #444;
  font-size: 15px;
  line-height: 1.74;
}

.login-register-wrap .login-register-form form .privacy-policy-wrap p a {
  color: #333;
}

.login-register-wrap .login-register-form form .privacy-policy-wrap p a:hover {
  color: #4e97fd;
}

/*-------- 23. Compare style ---------*/
.compare-table .table thead tr {
  border: none;
  background-color: #f8f8f8;
}

.compare-table .table thead tr th {
  border: none;
  padding: 13px 20px;
}

.compare-table .table thead tr th a.title {
  color: #333;
  font-size: 15px;
  font-weight: 400;
}

.compare-table .table thead tr th a.title:hover {
  color: #4e97fd;
}

.compare-table .table tbody tr {
  border: none;
  margin-bottom: 20px;
}

.compare-table .table tbody tr td {
  vertical-align: middle;
  border: none;
  padding: 13px 20px;
  color: #444;
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    min-width: 240px;
  }
}

.compare-table .table tbody tr td.first-column {
  min-width: 200px;
  font-size: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 15px 30px;
  text-align: center;
  background-color: #f8f8f8;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 100px;
    overflow: hidden;
  }
}

.compare-table .table tbody tr td.compare-rating i {
  color: #fb0;
  font-weight: 900;
  font-size: 14px;
}

.compare-table .table tbody tr td.compare-rating i.gray {
  color: #ababab;
  font-weight: 400;
}

.compare-table .table tbody tr td.compare-stock p i {
  color: #8cc63e;
  font-size: 15px;
  margin-right: 5px;
}

.compare-table .table tbody tr td.compare-rating span {
  font-weight: 700;
  font-size: 20px;
  color: #4e97fd;
}

.compare-table .table tbody tr td.compare-btn a {
  background: #edf4fe;
  border-radius: 26px;
  display: inline-block;
  font-size: 15px;
  padding: 12px 41px;
  font-weight: 700;
  color: #4e97fd;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.compare-btn a {
    padding: 12px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.compare-btn a {
    padding: 12px 28px;
  }
}

.compare-table .table tbody tr td.compare-btn a:hover {
  background-color: #4e97fd;
  color: #ffffff;
}

.compare-table .table tbody tr td p {
  font-size: 15px;
}

.compare-table .table tbody tr td span {
  font-size: 15px;
}

@media only screen and (max-width: 767px) {
  .compare-page-wrapper.pt-90 {
    padding-top: 20px;
  }
}

/*------ end Compare Page Wrapper -----*/
/*-------- 24. Blog details style ---------*/
.blog-details-wrapper .blog-details-top-content {
  margin: 0 0 30px;
}

.blog-details-wrapper .blog-details-top-content .post-categories a {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
  border-radius: 2px;
  padding: 1px 10px;
  background-color: #4e97fd;
}

.blog-details-wrapper .blog-details-top-content h1 {
  font-size: 34px;
  margin: 17px 0 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-details-top-content h1 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-details-top-content h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-top-content h1 {
    font-size: 26px;
  }
}

.blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li {
  font-size: 14px;
  color: #444;
  display: inline-block;
  margin-right: 20px;
}

.blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li i {
  margin-right: 6px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li i {
    margin-right: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li {
    margin-right: 8px;
  }
}

.blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li:last-child {
  margin-right: 0;
}

.blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li a {
  font-size: 14px;
  color: #444;
}

.blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li a img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 100%;
}

.blog-details-wrapper .blog-details-top-content .blog-meta-3 ul li a:hover {
  color: #4e97fd;
}

.blog-details-wrapper img {
  width: 100%;
  border-radius: 5px;
}

.blog-details-wrapper blockquote {
  padding-left: 1em;
  border-left: 4px solid #4e97fd;
  margin: 49px 0 45px 50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper blockquote {
    margin: 29px 0 25px 20px;
  }
}

.blog-details-wrapper blockquote p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #333;
  width: 90%;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper blockquote p {
    width: 96%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper blockquote p {
    width: 100%;
    font-size: 17px;
    line-height: 1.58;
  }
}

.blog-details-wrapper p {
  color: #444;
  font-size: 15px;
  line-height: 1.74;
}

.blog-details-wrapper p.blog-details-paragraph-mb {
  margin-bottom: 40px;
}

.blog-details-wrapper .blog-details-middle-img {
  margin: 45px 0 42px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-middle-img {
    margin: 25px 0 22px;
  }
}

.blog-details-wrapper .blog-details-column {
  margin: 0 0 40px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-column {
    margin: 0 0 20px;
  }
}

.blog-details-wrapper .blog-details-column h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 18px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-details-column h3 {
    font-size: 25px;
    margin: 0 0 12px;
  }
}

.blog-details-wrapper .blog-tag-share-wrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-tag-share-wrap {
    display: block;
  }
}

.blog-details-wrapper .blog-tag-share-wrap .blog-tag-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-tag-share-wrap .blog-tag-wrap {
    margin: 0 0 15px;
  }
}

.blog-details-wrapper .blog-tag-share-wrap .blog-tag-wrap span {
  color: #333;
  font-size: 18px;
  margin-right: 10px;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-tag-wrap .blog-tag a {
  font-size: 15px;
  color: #444;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-tag-wrap .blog-tag a:hover {
  color: #4e97fd;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap {
  display: flex;
  align-items: center;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-content span {
  font-weight: 500;
  margin-right: 15px;
  font-size: 15px;
  color: #333;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon {
  position: relative;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon span {
  font-size: 18px;
  color: #ffffff;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  border-radius: 100%;
  background-color: #4e97fd;
  cursor: pointer;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -10px);
  width: auto;
  white-space: nowrap;
  padding: 0 4px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0 2px 20px rgba(0, 0, 0, 0.06));
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list.bs-list-responsive {
    left: auto;
    right: 10px;
    transform: translate(0%, -10px);
  }
  .blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list.bs-list-responsive:before {
    left: auto;
    right: 10px;
    transform: none;
  }
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list::before {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  border-top: 8px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list.blog-share-right-0 {
  left: auto;
  right: 10px;
  transform: translate(0%, -10px);
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list.blog-share-right-0 {
    right: auto;
    left: 50%;
    transform: translate(-50%, -10px);
  }
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list.blog-share-right-0::before {
  left: auto;
  right: 10px;
  transform: none;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list.blog-share-right-0::before {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list a {
  display: inline-block;
  font-size: 15px;
  padding: 10px 12px;
  color: #666;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon .blog-share-list a:hover {
  color: #4e97fd;
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon:hover .blog-share-list {
  transform: translate(-50%, -20px);
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon:hover .blog-share-list.bs-list-responsive {
    transform: translate(0%, -20px);
  }
}

.blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon:hover .blog-share-list.blog-share-right-0 {
  transform: translate(0%, -20px);
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-tag-share-wrap .blog-share-wrap .blog-share-icon:hover .blog-share-list.blog-share-right-0 {
    transform: translate(-50%, -20px);
  }
}

.blog-details-wrapper .blog-author-wrap {
  display: flex;
  border-top: 1px solid #eee;
  margin-top: 50px;
  padding-top: 50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap {
    display: block;
    margin-top: 30px;
    padding-top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap {
    display: flex;
  }
}

.blog-details-wrapper .blog-author-wrap .blog-author-img {
  flex: 0 0 140px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap .blog-author-img {
    text-align: left;
  }
}

.blog-details-wrapper .blog-author-wrap .blog-author-img img {
  width: 100px;
  border-radius: 100%;
}

.blog-details-wrapper .blog-author-wrap .blog-author-img .blog-author-social {
  margin: 14px 0 0;
}

.blog-details-wrapper .blog-author-wrap .blog-author-img .blog-author-social a {
  color: #ababab;
  font-size: 15px;
  margin: 0 8px;
  display: inline-block;
}

.blog-details-wrapper .blog-author-wrap .blog-author-img .blog-author-social a:hover {
  color: #4e97fd;
}

.blog-details-wrapper .blog-author-wrap .blog-author-content {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap .blog-author-content {
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-details-wrapper .blog-author-wrap .blog-author-content {
    margin-left: 20px;
    margin-top: 0px;
  }
}

.blog-details-wrapper .blog-author-wrap .blog-author-content h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 10px;
}

.blog-details-wrapper .blog-prev-next-wrap {
  position: relative;
  margin: 43px 0 45px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-prev-next-wrap {
    margin: 23px 0 25px;
  }
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style {
  position: relative;
  overflow: hidden;
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a {
  display: block;
  border-radius: 5px;
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  background-color: #000;
  opacity: .5;
  z-index: 5;
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a img {
  width: 100%;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a h4 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.56;
  margin: 0;
  z-index: 9;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a h4 {
    font-size: 17px;
    line-height: 1.4;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a h4 {
    font-size: 17px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style a h4 {
    font-size: 16px;
    line-height: 1.4;
  }
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-next-position h4 {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 50px 10px 30px;
  text-align: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-next-position h4 {
    padding: 10px 50px 10px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-next-position h4 {
    padding: 10px 50px 10px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-next-position h4 {
    padding: 10px 40px 10px 20px;
  }
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-next-position h4:before {
  position: absolute;
  content: '\f105';
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Pro';
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-prev-position {
    margin: 0 0 15px;
  }
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-prev-position h4 {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 30px 10px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-prev-position h4 {
    padding: 10px 20px 10px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-prev-position h4 {
    padding: 10px 20px 10px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-prev-position h4 {
    padding: 10px 20px 10px 40px;
  }
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style.blog-prev-position h4:before {
  position: absolute;
  content: '\f104';
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Font Awesome 5 Pro';
}

.blog-details-wrapper .blog-prev-next-wrap .blog-prev-next-style:hover a img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.blog-details-wrapper .blog-reply-wrapper h3 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.blog-details-wrapper .blog-reply-wrapper p {
  margin: 0 0 30px;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-input-style {
  margin-bottom: 25px;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-textarea-style textarea {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 140px;
  color: #666;
  padding: 19px 20px 3px;
  border-radius: 5px;
  font-size: 15px;
  transition: all .3s ease 0s;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-textarea-style textarea::-moz-input-placeholder {
  color: #666;
  opacity: 1;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-textarea-style textarea::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-textarea-style textarea:focus {
  border: 1px solid #4e97fd;
  background-color: #ffffff;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-remember-wrap {
  display: flex;
  align-items: center;
  margin: 25px 0 26px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .blog-reply-wrapper form .blog-remember-wrap {
    margin: 12px 0 12px;
  }
}

.blog-details-wrapper .blog-reply-wrapper form .blog-remember-wrap input {
  width: auto;
  height: auto;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-remember-wrap label {
  color: #444;
  font-size: 15px;
  line-height: 1.74;
  margin: 0 0 0 10px;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-comment-submit input {
  width: auto;
  height: 48px;
  background-color: #4e97fd;
  color: #ffffff;
  border-radius: 26px;
  padding: 0 41px;
  font-size: 15px;
  font-weight: 700;
}

.blog-details-wrapper .blog-reply-wrapper form .blog-comment-submit input:hover {
  background-color: #e4573d;
}

@media only screen and (max-width: 767px) {
  .blog-details-area.pt-55 {
    padding-top: 40px;
  }
}
