/* Loop Animations */
// Buzz Out
@keyframes tm-animation-buzz-out {
	10% {
		transform: translateX(3px) rotate(2deg);
	}
	20% {
		transform: translateX(-3px) rotate(-2deg);
	}
	30% {
		transform: translateX(3px) rotate(2deg);
	}
	40% {
		transform: translateX(-3px) rotate(-2deg);
	}
	50% {
		transform: translateX(2px) rotate(1deg);
	}
	60% {
		transform: translateX(-2px) rotate(-1deg);
	}
	70% {
		transform: translateX(2px) rotate(1deg);
	}
	80% {
		transform: translateX(-2px) rotate(-1deg);
	}
	90% {
		transform: translateX(1px) rotate(0);
	}
	100% {
		transform: translateX(-1px) rotate(0);
	}
}

@keyframes tm-loop-move-vertical {
	0% {
		transform: translate3d(0, -5px, 0);
	}
	100% {
		transform: translate3d(0, 5px, 0);
	}
}

.loop-move-vertical {
	animation: 3s tm-loop-move-vertical linear infinite alternate;
}

@keyframes tm-loop-move-horizontal {
	0% {
		transform: translate3d(-5px, 0, 0);
	}
	100% {
		transform: translate3d(5px, 0, 0);
	}
}

.loop-move-horizontal {
	animation: 3s tm-loop-move-horizontal linear infinite alternate;
}

@keyframes tm-loop-rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

.loop-rotate {
	animation: 3s tm-loop-rotate linear infinite;
}

/* Hover Animations */

.medizin-animation-zoom-in {
	.medizin-image {
		overflow: hidden;

		img {
			width: 100%;
			transition: all 1.5s cubic-bezier(0, 0, .2, 1);
		}
	}

	.medizin-box {
		&:hover {
			.medizin-image {
				img {
					transform: scale3d(1.1, 1.1, 1.1);
				}
			}
		}
	}
}

.medizin-animation-zoom-out {
	.medizin-image {
		overflow: hidden;

		img {
			width: 100%;
			transition: all 1.5s cubic-bezier(0, 0, .2, 1);
			transform: scale3d(1.1, 1.1, 1.1);
		}
	}

	.medizin-box {
		&:hover {
			.medizin-image {
				img {
					transform: scale3d(1, 1, 1);
				}
			}
		}
	}
}

.medizin-animation-move-up {
	.medizin-box {
		.medizin-image {
			transition: all .3s cubic-bezier(0, 0, .2, 1);
		}

		&:hover {
			.medizin-image {
				transform: translateY(-10px);
			}
		}
	}
}

.medizin-animation-move-up-drop-shadow {
	.medizin-box {
		.medizin-image {
			position: relative;
			transition: all .3s cubic-bezier(0, 0, .2, 1);

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				top: 50%;
				left: 10%;
				right: 10%;
				box-shadow: 0 10px 50px rgba(51, 51, 51, .22);
				z-index: -1;
				opacity: 0;
				transition: opacity .3s linear;
			}
		}

		&:hover {
			.medizin-image {
				transform: translateY(-10px);

				&:after {
					opacity: 1;
				}
			}
		}
	}
}

/* Entrance Animations */
@keyframes medizinAnimationFadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 30px, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.tmFadeInUp {
	animation-name: medizinAnimationFadeInUp;

	&.animated {
		animation-duration: 1s;
	}
}

/* Entrance Grid Animation */
.medizin-grid {
	.grid-item {
		opacity: 0;
		transform: translate3d(0, 30px, 0);

		&.animate {
			transform: none;
			opacity: 1;
			// DO NOT apply transition all attributes to grid items. It's caused overlap layout on window resize.
			transition: transform 1s ease-in-out, opacity 1s linear;
		}
	}
}

@-webkit-keyframes moveHorizontal {
	0% {
	}
	100% {
		-webkit-transform: translateX(0);
		opacity: 1;
	}
}

@keyframes moveHorizontal {
	0% { 
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

body:not(.elementor-editor-active) {
	.modern-grid {
		.grid-item {
			opacity: 0;
			transform: translate3d(0, 30px, 0);

			&.animate {
				transform: none;
				opacity: 1;
				// DO NOT apply transition all attributes to grid items. It's caused overlap layout on window resize.
				transition: transform 1s ease-in-out, opacity 1s linear;
			}
		}
	}
}
