/*-------- 22. Login register style ---------*/

.login-register-wrap {
    &.login-register-gray-bg {
        background: #f8f8f8;
        border-radius: 5px;
        padding: 29px 50px 48px;
        margin: 0 100px 0 0;
        @media #{$lg-layout} {
            margin: 0 30px 0 0;
        } 
        @media #{$md-layout} {
            margin: 0 0px 40px 0;
        } 
        @media #{$xs-layout} {
            margin: 0 0px 40px 0;
            padding: 29px 20px 48px;
        } 
    }
    .login-register-title {
        h1 {
            font-size: 34px;
            font-weight: 500;
            margin: 0 0 21px;
            @media #{$xs-layout} {
                font-size: 30px;
            }
        }
    }
    .login-register-form {
        form {
            .login-register-input-style {
                margin: 0 0 30px;
                @media #{$xs-layout} {
                    margin: 0 0 20px;
                }
                label {
                    display: block;
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: #444;
                    margin-bottom: 8px;
                    @media #{$xs-layout} {
                        margin-bottom: 4px;
                    }
                }
                &.input-style-white {
                    input {
                        background-color: $white;
                    }
                }
            }
            .lost-remember-wrap {
                display: flex;
                justify-content: space-between;
                @media #{$xs-layout} {
                    display: block;
                } 
                .remember-wrap {
                    display: flex;
                    align-items: center;
                    @media #{$xs-layout} {
                        margin: 0 0 6px;
                    } 
                    input {
                        width: auto;
                        height: auto;
                    }
                    span {
                        display: block;
                        font-size: 15px;
                        color: #444;
                        margin-left: 15px;
                    }
                }
                .lost-wrap {
                    a {
                        font-size: 15px;
                        color: $theme-color-blue;
                        position: relative;
                        display: inline-block;
                        &::before, &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: #e7e7e7;
                            transform-origin: right;
                            transform: scaleX(1) translateZ(0);
                            transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
                        }
                        &::after {
                            transform-origin: left;
                            transform: scaleX(0) translateZ(0);
                            transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                            transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                        }
                        &:hover {
                            &::before {
                                background-color: #4E97FD;
                                transform: scaleX(0) translateZ(0);
                                transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
                            }
                            &::after {
                                background-color: #4E97FD;
                                transform: scaleX(1) translateZ(0);
                                transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s, -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
                            }
                        }
                    }
                }
            }
            .login-register-btn {
                margin: 35px 0 0;
                @media #{$xs-layout} {
                    margin: 25px 0 0;
                }
                button {
                    font-size: 15px;
                    font-weight: 700;
                    border: none;
                    background-color: $theme-color-blue;
                    color: $white;
                    display: block;
                    text-align: center;
                    border-radius: 26px;
                    width: 100%;
                    padding: 11px 10px 13px;
                    &:hover {
                        background-color: $theme-color-red;
                    }
                }
            }
            .privacy-policy-wrap {
                p {
                    color: #444;
                    font-size: 15px;
                    line-height: 1.74;
                    a {
                        color: #333;
                        &:hover {
                            color: $theme-color-blue;
                        }
                    }
                }
            }
        }
    }
}


