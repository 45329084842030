/*-------- 7. Brand logo style ---------*/

.single-brand-logo {
	display: flex;
	justify-content: center;
    a {
        display: block;
        img {
            max-width: 100%;
        }
    }
    &:hover {
        a {
            img {
                filter: grayscale(100%);
            }
        }
    }
}

@media #{$xs-layout} {
    .brand-logo-area {
        &.pb-35 {
            padding-bottom: 20px;
        }
        &.pb-70 {
            padding-bottom: 20px;
        }
    }
} 


